//import Image from "next/image";
//import Link from 'next/link';
import React, { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

//import { WishlistButton } from '@/components/wishlist';
//  import { Product } from "../../../types/Product";
//import { Product } from '@/types';
//import formatPrice from '@/utils/formatPrice';
//  import Card from "@mui/material/Card";
//import CardActions from '@mui/material/CardActions'
//  import CardContent from "@mui/material/CardContent";
//   import Typography from "@material-ui/core/Typography";

//import styles from "./ProductCard.module.css";
//import "./ProductCard.module.css";
import "./hasan.css";
//  import { getImageSize } from "react-image-size";
import { BagIcon } from "../../Common/Icon";
//  import Button from "../../Common/Button";
//import * as actions from "../../../containers/Cart/actions.js";

//import {
//  handle_AddOneToCart,
//  handle_RemoveOneFromCart,
//} from "../../../containers/Cart/cartactions.js";

//import {
//  HANDLE_CART,
//  ADD_TO_CART,
//  REMOVE_FROM_CART,
//  HANDLE_CART_TOTAL,
//  SET_CART_ID,
//  CLEAR_CART,
//} from "../../../containers/Cart/constants";
//  import { Divider } from "@mui/material";
// React Icons
//import { FaTrash } from "react-icons/fa6"
import { FaMinus } from "react-icons/fa";
import { FaPlus } from "react-icons/fa";
// React Icons
//import { BsCart4 } from "react-icons/bs";
import { AiOutlineShoppingCart } from "react-icons/ai";
import { Fancybox } from "../../../ext/Fancybox";

//interface Props {
//  product: Product;
//  isPr3: boolean;
//}
import AddToWishList from "../AddToWishList";

const ProductCard = (props) => {
  const {
    product,
    index,
    updateWishlist,
    authenticated,
    handle_AddOneToCart,
    handle_RemoveOneFromCart,
    handleAddToCart,
    handleRemoveFromCart,
    isPr3,
  } = props;

  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cart);
  //  const [refresh, setrefresh] = useState(false);
  //const dispatch = useDispatch();
  //const cart = useSelector((state) => state.cart);

  const inCart = product.incart;
  //const cart = useSelector((state) => state.cart);
  //if (inCart !== undefined) {
  //  console.log("inCart", inCart);
  //}
  let aspectratio = null;
  if (!product.price) {
    product.price = 0;
  }
  product.price = product.price * 1;
  try {
    aspectratio = JSON.parse(product.aspectratio || { width: 100, hight: 100 });
  } catch (err) {
    aspectratio = { width: 100, height: 100 };
  }

  //product.thumbsURL = product.imageUrl;
  //product.fotos = [];
  const prod = {
    taxable: false,
    isActive: true,
    brand: {
      isActive: true,
      _id: product.brand?.id, // "60136d36a44ceeaf670e7903",
      name: product.brand?.name, //"Apple",
      slug: product.brand?.name, //"apple",
    },
    _id: product._id, //"629d661f8bbadc6c80b02a9c",
    sku: "macbook-pro",
    name: product.name, //"Macbook Laptop",
    description: product.name, //"macbook pro 16-inch",
    quantity: 1,
    price: product.price,
    imageURL: product.imageURL, //imageURL,
    thumbsURL: product.thumbsURL, //.thumbsURL,
    //fotos: product.productvid.fotos,
    // "http://192.168.1.56:3027/files?dirname=1.KissMe/Sweaters&name=A5095.jpg",
    //"https://mernstore-bucket.s3.us-east-2.amazonaws.com/mbp-spacegray-select-202011_GEO_US.jpeg",
    imageKey: "mbp-spacegray-select-202011_GEO_US.jpeg",
    created: "2022-06-06T02:27:43.139Z",
    slug: "macbook-pro",
    __v: 0,
    inventory: 11398,
    totalPrice: product.price,
  };

  const Icon = (
    <span
      className="cart-icon"
      style={{
        //position: "relative",
        //lineHeight: "100%",
        padding: "12px 12px",
        //border: "none",
        backgroundColor: "#59ebb0", //#85eec2",
        //background: "transparent",
        //color: "inherit", //"#323232 !important",
        //width: 60,
      }}
    >
      <BagIcon />
      {/*   {cartItems?.length > 0 && (
      <span className="cart-badge">
        {cartItems?.length >= 99 ? "99+" : cartItems?.length}
      </span>
    )}
      */}
    </span>
  );

  let borderbox = "1px solid black";
  //console.log(product);

  return (
    <div key={index} className="mb-3 mb-md-0">
      <div className="product-container" style={{ borderRadius: 16 }}>
        <div className="item-box">
          <div className="add-wishlist-box">
            <AddToWishList
              id={product._id}
              liked={product?.isLiked ?? false}
              enabled={authenticated}
              updateWishlist={updateWishlist}
              authenticated={authenticated}
            />
          </div>

          <div className="item-link">
            {/* Images */}
            <div className="item-image-container" style={{ marginTop: 10 }}>
              <a>
                <span>
                  <img
                    alt=""
                    aria-hidden="true"
                    style={{
                      display: "block",
                      maxWidth: "100%",
                      width: "initial",
                      height: "initial",
                      background: "none",
                      opacity: "1",
                      border: "0px",
                      margin: "0px",
                      padding: "0px",
                    }}
                    src={
                      'data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="500" height="500"/>'
                    }
                  ></img>
                </span>

                <Fancybox
                  options={{
                    Hash: false,
                    //Thumbs: false,
                    compact: false,
                    contentClick: "toggleCover",
                    wheel: "slide",
                    //helpers: { title: "top" },
                    Toolbar: {
                      //*** */
                      items: {
                        title: {
                          tpl: `<div style=margin-left:-200px;margin-right:200px;margin-top:10px>test</div>`,
                          click: () => {
                            window.open(
                              `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
                                window.location.href
                              )}&t=${encodeURIComponent(document.title)}`,
                              "",
                              "left=0,top=0,width=600,height=300,menubar=no,toolbar=no,resizable=yes,scrollbars=yes"
                            );
                          },
                        },
                      },
                      //*** */
                      display: {
                        left: ["infobar"],
                        middle: ["zoomIn", "zoomOut", "toggle1to1"],
                        right: ["slideshow", "close"],
                      },
                    },
                    Images: {
                      //protected: true,
                      Panzoom: {
                        maxScale: 8,
                        //panMode: "mousemove",
                        //mouseMoveFactor: 1.1,
                        //mouseMoveFriction: 0.12,
                      },
                    },
                    Carousel: {
                      infinite: false,
                    },
                  }}
                >
                  <a data-fancybox="gallery" data-src={product.imageURL}>
                    <img
                      className="item-image"
                      src={product.thumbsURL}
                      data-src={product.imageURL}
                      alt={product.name}
                      style={{
                        position: "absolute",
                        inset: "0px",
                        boxSizing: "border-box",
                        padding: "0px",
                        border: "none",
                        margin: "auto",
                        display: "block",
                        width: "0px",
                        height: "0px",
                        minWidth: String(aspectratio.width) + "%",
                        maxWidth: String(aspectratio.width) + "%",
                        minHeight: String(aspectratio.height) + "%",
                        maxHeight: String(aspectratio.height) + "%",
                      }}
                    />
                  </a>
                  <div style={{ display: "none" }}>
                    {product.fotos.map((foto) => {
                      const URL_API = "https://ahis.online:8027";
                      const imageUrl =
                        URL_API +
                        "/files" +
                        "?dirname=" +
                        foto.dirname +
                        "&name=" +
                        foto.filename;
                      const thumbsUrl =
                        URL_API +
                        "/files" +
                        "?dirname=.thumbs/" +
                        foto.dirname +
                        "&name=" +
                        foto.filename;
                      let aspr = JSON.parse(
                        foto.aspectratio || { width: 100, hight: 100 }
                      );
                      if (product.imageURL !== imageUrl)
                        return (
                          <a
                            key={foto.id}
                            data-fancybox="gallery"
                            data-src={imageUrl}
                          >
                            <img
                              src={thumbsUrl}
                              data-src={imageUrl}
                              alt={product.name}
                              style={{
                                position: "absolute",
                                inset: "0px",
                                boxSizing: "border-box",
                                padding: "0px",
                                border: "none",
                                margin: "auto",
                                display: "block",
                                width: aspr.width,
                                height: aspr.height,
                                //width: "0px",
                                //height: "0px",
                                minWidth: String(aspectratio.width) + "%",
                                maxWidth: String(aspectratio.width) + "%",
                                minHeight: String(aspectratio.height) + "%",
                                maxHeight: String(aspectratio.height) + "%",
                              }}
                            />
                          </a>
                        );
                    })}
                  </div>
                </Fancybox>
              </a>
            </div>
            {/* Images */}
            {/* item-Name */}
            <div className="item-body">
              <div className="item-details p-3">
                <h1 className="item-name">{product.name}</h1>
              </div>
            </div>
            {/* item-Name ? */}
            {/* item-description */}
            <div className="item-body1" style={{ marginTop: -30 }}>
              <div className="item-details p-3">
                <h1 className="item-name">
                  {product.brand?.name + "  " + product.description}
                </h1>
              </div>
            </div>
            {/* item-description ? */}
            <div
              style={{ marginLeft: 10, maxWidth: "95%" }}
              className="d-flex flex-row justify-content-between "
              //style={{ margin: "auto", maxWidth: "100%" }}
              //className="d-flex flex-row justify-content-between align-items-center px-4 mb-2 item-footer"
            >
              {/* <div className="price mb-0">{product.price} грн.</div> */}
              <div className="fs-5">
                <span className="price fw-bold">
                  {product?.price?.toFixed(0) + "  "}
                </span>
                <span className="me-1 fw-bold">грн.</span>
              </div>

              {/* - + */}
              <div
                className="d-flex edit border border-1 py-1 px-2 fw-bold mb-3"
                style={{
                  //marginLeft: 15,
                  backgroundColor:
                    !inCart || inCart?.quantity === "0" ? "" : "#00ff9f54",
                }}
              >
                <div
                  className={`px-2 minus d-flex justify-content-center align-items-center ${
                    !inCart || inCart?.quantity < 1
                      ? "quantity-greater-than-two"
                      : ""
                  }`}
                  onClick={() => {
                    //dispatch(removeOne(product?.id));
                    //     handle_RemoveOneFromCart(prod, cart, dispatch);
                    //handleRemoveFromCart(product);
                    handle_RemoveOneFromCart(prod, cart, dispatch);
                  }}
                >
                  <FaMinus />
                </div>
                <div className="incart d-flex justify-content-center align-items-center fw-bold">
                  {inCart?.quantity || "0"}
                </div>
                <div
                  className="px-2 plus d-flex justify-content-center align-items-center"
                  onClick={() => {
                    //dispatch(add(product));
                    //  handle_AddOneToCart(prod, cart, dispatch);
                    //handleAddToCart(product);
                    handle_AddOneToCart(prod, cart, dispatch);
                    //setrefresh(!refresh);
                  }}
                >
                  <FaPlus />
                </div>
              </div>
              {/* - + */}
            </div>
            {/*
            <div
              //className={"productInfo"}
              style={{
                //width: "90%",
                marginLeft: 5,
                fontSize: "1.20rem",
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "column",
                flexWrap: "wrap",
              }}
            >
              <div
                className={"productInfo1"}
                style={{
                  //marginLeft: 10,
                  //fontSize: "1.20rem",
                  width: "100%",
                  padding: 10,
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "row",
                  flexWrap: "nowrap",
                }}
              >
                <div className="fs-5">
                  <span className="price fw-bold">
                    {product?.price?.toFixed(0) + "  "}
                  </span>
                  <span className="me-1 fw-bold">грн.</span>
                </div>
                <div
                  className="d-flex edit border border-1 py-1 px-2 fw-bold mb-3"
                  style={{
                    backgroundColor:
                      !inCart || inCart?.quantity === "0" ? "" : "#00ff9f54",
                  }}
                >
                  <div
                    className={`px-2 minus d-flex justify-content-center align-items-center ${
                      !inCart || inCart?.quantity < 1
                        ? "quantity-greater-than-two"
                        : ""
                    }`}
                    onClick={() => {
                      handleRemoveFromCart(product);
                    }}
                  >
                    <FaMinus />
                  </div>
                  <div className="incart d-flex justify-content-center align-items-center">
                    {inCart?.quantity || "0"}
                  </div>
                  <div
                    className="px-2 plus d-flex justify-content-center align-items-center"
                    onClick={() => {
                      handleAddToCart(product);
                    }}
                  >
                    <FaPlus />
                  </div>
                </div>
              </div>

              <div className="d-flex justify-content-between align-items-center w-100 p-2">
                <div className="fs-5">
                  <span className="price fw-bold">{product?.name}</span>
                </div>
                {(!inCart || inCart?.quantity === "0") && (
                  <button
                    type="button"
                    style={{
                      fontSize: "x-large",
                      color:
                        "rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important",
                    }}
                    className="flex items-center space-x-2 hover:bg-blue-500 text-white py-2 px-4 rounded bg-pink-500"
                    //disabled={true}
                    onClick={() => {
                      //dispatch(add(product));
                      // handle_AddOneToCart(prod, cart, dispatch);
                      handleAddToCart(product);
                    }}
                    data-test="add-cart-btn"
                  >
                    <AiOutlineShoppingCart />
                    {/*<span>ADD TO CART</span> * /}
                  </button>
                )}
              </div>
            </div>
*/}
          </div>
          {/* item-link */}
        </div>
        {/* item-box */}
      </div>
      {/* product-container */}
    </div>
  );
};

export default ProductCard;
