/**
 *
 * Filter
 *
 */

import React, { useState } from "react";

import { NavLink } from "react-router-dom";
import { Container } from "reactstrap";

import actions from "../../actions";
import { connect } from "react-redux";

import Button from "../../components/Common/Button";
import { CloseIcon } from "../../components/Common/Icon";
import { List } from "../../components/Common/List";

//class Filter extends React.PureComponent {

const Filter = (props) => {
  //const [clickCategory, setclickCategory] = useState(false);
  //  render() {
  const {
    isFilterOpen,
    categories,
    toggleMenu,
    OpenCategory,
    SelectedCategory,
    set_OpenCategory,
    set_SelectedCategory,
  } = props;
  var cloneDeep = require("lodash.clonedeep");

  const handleCategoryClick = async (item) => {
    set_SelectedCategory(item);
    props.toggleMenu();
  };

  const handleMainCategoryClick = (id) => {
    if (OpenCategory !== id) {
      //setopenMainCategory(id);
      set_OpenCategory(id);
    } else {
      //setopenMainCategory(null);
      set_OpenCategory(null);
    }
  };

  return (
    <div className="navigation-menu">
      <div className="menu-header">
        {isFilterOpen && (
          <Button
            borderless
            variant="empty"
            ariaLabel="close the menu"
            icon={<CloseIcon />}
            onClick={toggleMenu}
          />
        )}
      </div>
      <div className="menu-body" style={{ marginTop: -50 }}>
        <Container>
          <h3 className="menu-title">Категории</h3>
          <nav role="navigation">
            <ul
              className="menu-list"
              style={{
                maxHeight: "100%",
                overflow: "auto",
                padding: 0,
                margin: 0,
                listStyle: "none",
              }}
            >
              {categories?.map((link, index) => {
                let items;
                if (link.items) items = cloneDeep(link.items);
                else items = [];

                items.push({
                  id: link.id,
                  name: "<Все - " + link.name + " >",
                });
                return (
                  <>
                    <li
                      key={index}
                      className="menu-item"
                      style={{
                        marginLeft: 0,
                        marginTop: -10,
                      }}
                      //onClick={handleMainCategoryClick}
                      onClick={(item) => {
                        handleMainCategoryClick(link.id);
                      }}
                    >
                      <a>{link.name}</a>
                    </li>
                    <ul
                      id={link.id}
                      style={{
                        display:
                          !OpenCategory || OpenCategory !== link.id
                            ? "none"
                            : "",
                        //padding: "17px 0",
                        marginTop: -10,
                        //listStyle: "none",
                      }}
                    >
                      {items.map((item, index) => {
                        return (
                          <>
                            <li
                              key={index}
                              className="menu-item"
                              style={{
                                marginLeft: 40,
                              }}
                            >
                              <NavLink
                                //onClick={handleCategoryClick}
                                onClick={async () => {
                                  await handleCategoryClick(item.id);
                                }}
                                to={"/shop/category/" + (item.slug || item.id)}
                                activeClassName="active-link"
                                exact
                                style={{
                                  padding: "4px 0px 0 20px",
                                  //SelectedCategory
                                  height: 30,
                                  borderRadius:
                                    SelectedCategory === item.id ? 30 : 0,
                                  //verticalAlign: "middle",
                                  border:
                                    SelectedCategory === item.id
                                      ? "1px solid"
                                      : "", // #D93040",

                                  /*
                                  width: 100%;
      display: inline-block;
      padding: 0 55px 0 25px;
      border-radius: 40px;
      vertical-align: middle;
      border: 1px solid #D93040;
  //
  
      display: block;
      padding: 10px 20px;
      color: #ffffff;
      text-decoration: none;
  
  
      */
                                }}
                              >
                                {item.name}
                              </NavLink>
                            </li>
                          </>
                        );
                      })}
                    </ul>
                  </>
                );
              })}
            </ul>
          </nav>
        </Container>
      </div>
    </div>
  );
  //}
};

const mapStateToProps = (state) => {
  return {
    isFilterOpen: state.navigation.isFilterOpen,
    categories: state.category?.storeCategories,
    OpenCategory: state.filter.OpenCategory,
    SelectedCategory: state.filter.SelectedCategory,
  };
};

export default connect(mapStateToProps, actions)(Filter);
