/*
 *
 * Cart
 *
 */

import React from "react";
import { connect } from "react-redux";

import actions from "../../actions";

import CartList from "../../components/Store/CartList";
import CartSummary from "../../components/Store/CartSummary";
import Checkout from "../../components/Store/Checkout";
import { BagIcon, CloseIcon } from "../../components/Common/Icon";
import Button from "../../components/Common/Button";
import Switch, { SwitchProps } from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";

class Cart extends React.PureComponent {
  render() {
    const {
      isCartOpen,
      cartItems,
      cartTotal,
      cartstyle,
      toggleCartStyle,
      toggleCart,
      handleShopping,
      handleCheckout,
      handleRemoveFromCart,
      handle_AddOneToCart,
      handle_RemoveOneFromCart,
      placeOrder,
      authenticated,
    } = this.props;

    return (
      <div className="cart">
        <div className="cart-header">
          <div style={{ marginLeft: 40 }}>
            <FormControlLabel
              style={{
                marginRight: 10,
                marginLeft: 60,
              }}
              control={
                <Switch
                  id={"isworker"}
                  name={"isworker"}
                  color="primary"
                  //                          onFocus={onFocus}
                  checked={cartstyle}
                  onChange={(item, value) => {
                    toggleCartStyle();
                  }}
                  //disabled={getAccessToChange()}
                />
              }
              label={"Cart Style"} //translate('resources.switch.isworker')}
            />
          </div>
          {isCartOpen && (
            <Button
              className="close-the-cart"
              borderless
              variant="empty"
              ariaLabel="close the cart"
              icon={<CloseIcon />}
              onClick={toggleCart}
            />
          )}
        </div>
        {cartItems.length > 0 ? (
          <div className="cart-body">
            <CartList
              toggleCart={toggleCart}
              cartstyle={cartstyle}
              cartItems={cartItems}
              handleRemoveFromCart={handleRemoveFromCart}
              handle_AddOneToCart={handle_AddOneToCart}
              handle_RemoveOneFromCart={handle_RemoveOneFromCart}
            />
          </div>
        ) : (
          <div className="empty-cart">
            <BagIcon fillcolor="black" />
            <p>Ваша корзина пустая...</p>
          </div>
        )}
        {cartItems.length > 0 && (
          <div className="cart-checkout">
            <CartSummary cartTotal={cartTotal} />
            <Checkout
              handleShopping={handleShopping}
              handleCheckout={handleCheckout}
              placeOrder={placeOrder}
              authenticated={authenticated}
            />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isCartOpen: state.navigation.isCartOpen,
    cartItems: state.cart.cartItems,
    cartTotal: state.cart.cartTotal,
    cartstyle: state.cart.cartstyle,
    authenticated: true, //state.authentication.authenticated,
  };
};

export default connect(mapStateToProps, actions)(Cart);
