import { combineReducers } from "redux";
import { createBrowserHistory } from "history";
//import { connectRouter } from 'connected-react-router'
import { createRouterReducer } from "../../redux-react-router"; //'@lagunovsky/redux-react-router'
//import { createRouterReducer } from '@lagunovsky/redux-react-router'

// import reducers
import appReducer from "./app";
import cartReducer from "../../containers/Cart/reducer";
import navigationReducer from "../../containers/Navigation/reducer";
import productReducer from "../../containers/Product/reducer";
import navigationFilterReducer from "../../containers/Filter/reducer";

const history = createBrowserHistory();

const reducer = combineReducers({
  app: appReducer,
  router: createRouterReducer(history), // connectRouter(history),
  cart: cartReducer,
  product: productReducer,
  filter: navigationFilterReducer,
  navigation: navigationReducer,
});

export { reducer, history };
