/**
 *
 * CartList
 *
 */

import React from "react";

import { Link } from "react-router-dom";
//import { Container, Row, Col } from 'reactstrap';

import Button from "../../Common/Button";
import { AiOutlineDelete } from "react-icons/ai";
import { FaMinus } from "react-icons/fa";
import { FaPlus } from "react-icons/fa";

const CartList = (props) => {
  const {
    cartItems,
    cartstyle,
    handleRemoveFromCart,
    handle_AddOneToCart,
    handle_RemoveOneFromCart,
  } = props;

  const handleProductClick = () => {
    props.toggleCart();
  };

  return (
    <div className="cart-list">
      {cartItems.map((item, index) => {
        //console.log(index);
        if (!cartstyle)
          return (
            <div key={index} className="item-box">
              <div className="item-details">
                <div className="container">
                  <div
                    className="mb-2 align-items-center row"
                    style={{
                      marginTop: -10,
                      borderBottom: "1px solid #a1887f",
                    }}
                  >
                    <div className="pr-0 ">
                      <h1 className="item-name ">
                        <span
                          className={"price fw-bold"}
                          style={{
                            marginLeft: 0,
                            marginRight: 10,
                          }}
                        >
                          {index + 1 + ")"}
                        </span>
                        <span className="price fw-bold">
                          {item.brand?.name + " / " + item.name}
                        </span>
                      </h1>
                    </div>
                  </div>
                  <div
                    className={"productInfo1"}
                    style={{
                      marginTop: 0,
                      marginBottom: -20,
                      //marginLeft: 10,
                      //fontSize: "1.20rem",
                      width: "100%",
                      //padding: 5,
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: "row",
                      flexWrap: "nowrap",
                    }}
                  >
                    <div className="fs-5">
                      <span
                        className="price fw-bold"
                        onClick={() => {
                          alert("Price");
                        }}
                      >
                        {item?.price?.toFixed(0) + "  "}
                      </span>
                      <span className="me-1 fw-bold">грн.</span>
                    </div>

                    <div
                      className="d-flex edit border border-1 py-1 px-2 fw-bold mb-3"
                      style={{
                        backgroundColor:
                          !item || item?.quantity === "0" ? "" : "#00ff9f54",
                      }}
                    >
                      <div
                        className={`px-2 minus d-flex justify-content-center align-items-center ${
                          item?.quantity < 2 ? "quantity-greater-than-two" : ""
                        }`}
                        onClick={() => {
                          //dispatch(removeOne(product?.id));
                          handle_RemoveOneFromCart(item, { cartItems });
                        }}
                      >
                        <FaMinus />
                      </div>
                      <div
                        className="price incart d-flex justify-content-center align-items-center"
                          onClick={() => {
                            alert("Quantity");
                          }}
                      >
                        {item?.quantity || "0"}
                      </div>
                      <div
                        className="px-2 plus d-flex justify-content-center align-items-center"
                        onClick={() => {
                          //dispatch(add(product));
                          handle_AddOneToCart(item, { cartItems });
                        }}
                      >
                        <FaPlus />
                      </div>
                    </div>
                    <div className="fs-5">
                      <span className="price fw-bold">
                        {item?.totalPrice?.toFixed(0) + "  "}
                      </span>
                      <span className="me-1 fw-bold">грн.</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        else
          return (
            <div key={index} className="item-box">
              <div className="item-details">
                <div className="container">
                  <div className="mb-2 align-items-center row">
                    <div className="pr-0 col-10">
                      <div className="d-flex align-items-center">
                        <img
                          className="item-image mr-2"
                          src={`${
                            item.thumbsURL
                              ? item.thumbsURL
                              : "/images/placeholder-image.png"
                          }`}
                        />

                        <Link
                          to={`/product/${item.slug}`}
                          className="item-link one-line-ellipsis"
                          onClick={handleProductClick}
                        >
                          <h1
                            className="item-name one-line-ellipsis"
                            style={{ marginLeft: 15 }}
                          >
                            <span className="price fw-bold">{item.name}</span>
                          </h1>
                        </Link>
                      </div>
                    </div>
                    <div xs="2" className="text-right col-1">
                      <button
                        type="button"
                        className="flex items-center  text-white py-1 px-2 rounded bg-blue-500"
                        //borderless
                        //variant="empty"
                        //ariaLabel={`remove ${item.name} from cart`}
                        //icon={<AiOutlineDelete />}
                        //<i className="icon-trash" aria-hidden="true" />}
                        onClick={() => {
                          //handle_RemoveFromCart(item, dispatch);
                          handleRemoveFromCart(item);
                        }}
                      >
                        <AiOutlineDelete />
                      </button>
                    </div>
                  </div>

                  <div
                    className={"productInfo1"}
                    style={{
                      marginTop: -10,
                      marginBottom: -25,

                      //marginLeft: 10,
                      //fontSize: "1.20rem",
                      width: "100%",
                      padding: 10,
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: "row",
                      flexWrap: "nowrap",
                    }}
                  >
                    <div className="fs-5">
                      <span className="price fw-bold">
                        {item?.price?.toFixed(0) + "  "}
                      </span>
                      <span className="me-1 fw-bold">грн.</span>
                    </div>

                    <div
                      className="price d-flex edit border border-1 py-1 px-2 fw-bold mb-3"
                      style={{
                        backgroundColor:
                          !item || item?.quantity === "0" ? "" : "#00ff9f54",
                      }}
                    >
                      <div
                        className={`px-2 minus d-flex justify-content-center align-items-center ${
                          item?.quantity < 2 ? "quantity-greater-than-two" : ""
                        }`}
                        onClick={() => {
                          //dispatch(removeOne(product?.id));
                          handle_RemoveOneFromCart(item, { cartItems });
                        }}
                      >
                        <FaMinus />
                      </div>
                      <div className="incart d-flex justify-content-center align-items-center">
                        {item?.quantity || "0"}
                      </div>
                      <div
                        className="px-2 plus d-flex justify-content-center align-items-center"
                        onClick={() => {
                          //dispatch(add(product));
                          handle_AddOneToCart(item, { cartItems });
                        }}
                      >
                        <FaPlus />
                      </div>
                    </div>
                    <div className="fs-5">
                      <span className="price fw-bold">
                        {item?.totalPrice?.toFixed(0) + "  "}
                      </span>
                      <span className="me-1 fw-bold">грн.</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
      })}
    </div>
  );
};

export default CartList;
