/*
 *
 * Cart constants
 *
 */

export const HANDLE_CART = "src/Cart/HANDLE_CART";
export const ADD_TO_CART = "src/Cart/ADD_TO_CART";
export const REMOVE_FROM_CART = "src/Cart/REMOVE_FROM_CART";
export const REMOVE_ONE_FROM_CART = "src/Cart/REMOVE_ONE_FROM_CART";
export const HANDLE_CART_TOTAL = "src/Cart/HANDLE_CART_TOTAL";
export const SET_CART_ID = "src/Cart/SET_CART_ID";
export const CLEAR_CART = "src/Cart/CLEAR_CART";
export const TOGGLE_CART_STYLE= "src/Cart/TOGGLE_CART_STYLE";