import { useState, useCallback, useEffect } from "react";
//import moment from 'moment'
import { SocketManager } from "../ext/SocketManager";
import { getURLAPI } from "../utils/getURL";
import detect from "../lib/detect/detect";

const storageName = "userData";

export const useAuth = () => {
  const [token, setToken] = useState(null); //LocalStorage
  const [ready, setReady] = useState(false);
  const [userId, setUserId] = useState(null); //LocalStorage
  const [WorkDate, setWorkDate] = useState(null); //LocalStorage
  const [StartDate, setstartdate] = useState(null); //LocalStorage
  const [EndDate, setenddate] = useState(null); //LocalStorage
  const [Worker, setworker] = useState(null); //LocalStorage
  const [Worker2, setworker2] = useState(null); //LocalStorage
  const [Customer, setcustomer] = useState(null); //LocalStorage
  const [homeType, sethometype] = useState(null); //LocalStorage

  const [dispatchAccess, setdispatchaccess] = useState(false); //null)
  const [ws_client, setWs_client] = useState(null);

  const [config, setconfig] = useState(null);
  const [isRead, setisRead] = useState(false);

  const [IP, setip] = useState("");
  const [localIP, setLocalIP] = useState("");
  const [CFG, setCFG] = useState(null);

  const [getlocalCFG, setGetLocalCFG] = useState(false);

  const setIP = useCallback((value) => {
    setip(value);
  }, []);

  const setConfig = useCallback((value) => {
    setconfig(value);
  }, []);

  const setIsRead = useCallback((value) => {
    setisRead(value);
  }, []);

  const login = useCallback((jwtToken, id) => {
    setToken(jwtToken);
    setUserId(id);

    localStorage.setItem(
      storageName,
      JSON.stringify({
        ...JSON.parse(localStorage.getItem(storageName)),
        user: {
          userId: id,
          token: jwtToken,
        },
      })
    );
  }, []);

  const setdt = useCallback((date) => {
    setWorkDate(date);

    localStorage.setItem(
      storageName,
      JSON.stringify({
        ...JSON.parse(localStorage.getItem(storageName)),
        Date: date,
      })
    );
  }, []);

  const setStartDate = useCallback((date) => {
    setstartdate(date);

    localStorage.setItem(
      storageName,
      JSON.stringify({
        ...JSON.parse(localStorage.getItem(storageName)),
        StartDate: date,
      })
    );
  }, []);

  const setEndDate = useCallback((date) => {
    setenddate(date);

    localStorage.setItem(
      storageName,
      JSON.stringify({
        ...JSON.parse(localStorage.getItem(storageName)),
        EndDate: date,
      })
    );
  }, []);

  const setWorker = useCallback((value) => {
    setworker(value);

    localStorage.setItem(
      storageName,
      JSON.stringify({
        ...JSON.parse(localStorage.getItem(storageName)),
        Worker: value,
      })
    );
  }, []);

  const setWorker2 = useCallback((value) => {
    setworker2(value);

    localStorage.setItem(
      storageName,
      JSON.stringify({
        ...JSON.parse(localStorage.getItem(storageName)),
        Worker2: value,
      })
    );
  }, []);

  const setdispatchAccess = useCallback((value) => {
    setdispatchaccess(value);
  }, []);

  const setCustomer = useCallback((value) => {
    setcustomer(value);

    localStorage.setItem(
      storageName,
      JSON.stringify({
        ...JSON.parse(localStorage.getItem(storageName)),
        Customer: value,
      })
    );
  }, []);

  const sethomeType = useCallback((value) => {
    sethometype(value);

    localStorage.setItem(
      storageName,
      JSON.stringify({
        ...JSON.parse(localStorage.getItem(storageName)),
        homeType: value,
      })
    );
  }, []);

  function get_cookie(name) {
    return document.cookie.split(";").some((c) => {
      return c.trim().startsWith(name + "=");
    });
  }
  function delete_cookie(name, path, domain) {
    if (get_cookie(name)) {
      document.cookie =
        name +
        "=" +
        (path ? ";path=" + path : "") +
        (domain ? ";domain=" + domain : "") +
        ";expires=Thu, 01 Jan 1970 00:00:01 GMT";
    }
  }

  const logout = useCallback(() => {
    setToken(null);
    setUserId(null);

    localStorage.removeItem(storageName);

    setdispatchAccess(null);

    //delete_cookie('user_Id')
    //delete_cookie('token')
    //    var cookies = document.cookie
    //    console.log('cookies *', cookies)
  }, []);

  function getCookie(name) {
    let cookie = {};
    document.cookie.split(";").forEach(function (el) {
      let [k, v] = el.split("=");
      cookie[k.trim()] = v;
    });
    return cookie[name];
  }

  const getLocalCFG = () => {
    try {
      let myIP;
      window.RTCPeerConnection =
        window.RTCPeerConnection ||
        window.mozRTCPeerConnection ||
        window.webkitRTCPeerConnection; //compatibility for Firefox and chrome
      let pc = new RTCPeerConnection({ iceServers: [] }),
        noop = function () {};
      pc.createDataChannel(""); //create a bogus data channel
      pc.createOffer(pc.setLocalDescription.bind(pc), noop); // create offer and set local description
      pc.onicecandidate = function (ice) {
        if (ice && ice?.candidate && ice?.candidate?.candidate) {
          try {
            myIP =
              /([0-9]{1,3}(\.[0-9]{1,3}){3}|[a-f0-9]{1,4}(:[a-f0-9]{1,4}){7})/.exec(
                ice?.candidate?.candidate
              )[1];
          } catch (err) {
            console.log(err);
            myIP = "0:0:0:1";
            setLocalIP(myIP);
          }

          //console.log("my IP: ", myIP);
          pc.onicecandidate = noop;
          setLocalIP(myIP);
        }
      };
    } catch (err) {
      console.log(err);
      setLocalIP("0:0:0:0");
    }
    let ua = detect.parse(navigator.userAgent);
    let cfg = {
      browser: ua.browser,
      device: ua.device,
      isMobile: ua.isMobile,
      os: ua.os,
    };

    setCFG(cfg);
  };

  function getOS() {
    var userAgent = window.navigator.userAgent,
      platform = window.navigator.platform,
      macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"],
      windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"],
      iosPlatforms = ["iPhone", "iPad", "iPod"],
      os = null;

    if (macosPlatforms.indexOf(platform) !== -1) {
      os = "Mac OS";
    } else if (iosPlatforms.indexOf(platform) !== -1) {
      os = "iOS";
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
      os = "Windows";
    } else if (/Android/.test(userAgent)) {
      os = "Android";
    } else if (!os && /Linux/.test(platform)) {
      os = "Linux";
    }

    return os;
  }

  //if (!getlocalCFG) {
  //    setGetLocalCFG(true);
  //    getLocalCFG();
  //  }

  useEffect(() => {
    if (userId && token && config && !ws_client) {
      try {
        let wss = new SocketManager({
          URL_WS: getURLAPI(config, "URL_WS"), //get address WebSocket BK from config
          auth: {
            userId: userId,
            tocken: token,
            cfg: { ip: IP?.ip /*, OS: getOS()*/, localIp: localIP, CFG },
          },
        });
        setWs_client(wss);
      } catch (error) {
        console.log("Websocket connect error ", error);
      }
    }
  }, [localIP, IP, userId, token, config]);

  useEffect(() => {
    let data;
    try {
      data = JSON.parse(localStorage.getItem(storageName));
    } catch (e) {
      console.log("ERR :", e);
      localStorage.removeItem(storageName);
    }

    if (data && data.user && data.user.token) {
      login(data.user.token, data.user.userId);
    } else {
      localStorage.removeItem(storageName);
    }

    if (data && data.Date) {
      setdt(data.Date);
    } else {
      setdt(new Date(Math.floor(new Date().getTime() / 86400000) * 86400000));
    }

    if (data && data.StartDate) {
      setstartdate(data.StartDate);
    }

    if (data && data.EndDate) {
      setenddate(data.EndDate);
    }

    if (data && data.Worker) {
      setworker(data.Worker);
    }

    if (data && data.Worker2) {
      setworker2(data.Worker2);
    }

    if (data && data.Customer) {
      setcustomer(data.Customer);
    }

    if (data && data.homeType) {
      sethometype(data.homeType);
    }

    setReady(true);
  }, [login, config]);

  return {
    login,
    logout,
    token,
    userId,
    ready,
    WorkDate,
    setdt,
    StartDate,
    setStartDate,
    EndDate,
    setEndDate,
    Worker,
    setWorker,
    Worker2,
    setWorker2,
    Customer,
    setCustomer,
    homeType,
    sethomeType,
    dispatchAccess,
    setdispatchAccess,
    config,
    setConfig,
    ws_client,
    isRead,
    setIsRead,
    IP,
    setIP,
  };
};
