/*
 *
 * Brand actions
 *
 */
import {
  SET_WEBSOCKET,
  SET_WEBSOCKETAUTH,
  SET_WEBSOCKETURL_WS,
  SET_CONFIG,
  SET_ISLOADING,
  SET_ISACCESSLOADING,
  SET_ACCESS,
} from "./constants";

import axios from "axios";
import { Client as WebSocket } from "rpc-websockets";

import handleError from "../../utils/error";
//import { API_URL, URL_WS } from "../../constants";
import { getURLAPI, getBASEURL } from "../../utils/getURL";
import { SocketManager } from "../../ext/SocketManager";

export const getConfig = () => {
  return (dispatch, getState) => {
    const state = getState();
    return state.WebSocket.config;
  };
};
export const setConfig = (config) => {
  return async (dispatch, getState) => {
    dispatch({ type: SET_CONFIG, payload: config });
  };
};

export const setAccess = (access) => {
  return async (dispatch, getState) => {
    //console.log("set acssess ", access);
    dispatch({ type: SET_ACCESS, payload: access });
  };
};

export const setIsLoading = (e) => {
  return (dispatch, getState) => {
    dispatch({
      type: SET_ISLOADING,
      payload: e,
    });
  };
};

// fetch store brands api
export const createWS = () => {
  return async (dispatch, getState) => {
    const state = getState();
    //console.log("createWS state=", state);

    let config = state.WebSocket.config;
    let URL_WS = getURLAPI(config, "URL_WS"); //get address WebSocket BK from config

    let token = localStorage.getItem("token");
    let userId = localStorage.getItem("userId"); // "38ad21bd-13bb-4966-85d7-433c550b2f9d";

    let ws_client = state.WebSocket.ws_client;
    try {
      if (!ws_client) {
        if (userId && token && !ws_client) {
          try {
            let wss = new SocketManager({
              URL_WS: URL_WS, //getURLAPI(config, "URL_WS"), //get address WebSocket BK from config
              auth: {
                userId: userId,
                tocken: token,
                cfg: {}, //{ ip: IP?.ip /*, OS: getOS()*/, localIp: localIP, CFG },
              },
            });
            //console.log("create websocket Ok!");
            dispatch({
              type: SET_WEBSOCKETURL_WS,
              payload: URL_WS,
            });
            dispatch({
              type: SET_WEBSOCKETAUTH,
              payload: {
                userId: userId,
                tocken: token,
              },
            });

            dispatch({ type: SET_WEBSOCKET, payload: wss });
          } catch (error) {
            console.log("Websocket connect error ", error);
          }
        }

        //
      }
    } catch (err) {
      console.log("err", err);
    }
  };
};

export const open_process1 = (params = null) => {
  return async (dispatch, getState) => {
    const state = getState();
    let ws_client = state.WebSocket.ws_client;
    ws_client.open_process(params);
  };
};

export const open_process = (params = null) => {
  return async (dispatch, getState) => {
    const state = getState();
    let ws_client = state.WebSocket.ws_client;
    //let cartItems = state.cart.cartItems;

    if (!ws_client) return;
    //if (!ws_client || !ws_client.ws_login) return;

    const GetData_handle = function (_result) {
      const { tableName, result, readonly, action } = _result;
      //console.log('result handle1 ', result

      for (let i in params.models) {
        if (tableName === params.models[i].tableName) {
          dispatch({
            type: params.models[i].typefetch,
            payload: result,
            //cartItems,
          });
        }
      }
    };

    if (!params.handleGetData) {
      params.handleGetData = GetData_handle;
    }

    if (!params.auth) params.auth = state.WebSocket.auth;
    if (!params.URL_WS) params.URL_WS = state.WebSocket.URL_WS;

    try {
      ws_client.open_process(params);
    } catch (err) {
      console.log(err);
    }
  };
};

export const wss_setItem = (params = null) => {
  return async (dispatch, getState) => {
    const state = getState();
    let ws_client = state.WebSocket.ws_client;
    ws_client.wss_setItem(params);
  };
};

// fetch store brands api
export const fetchTable = (params2 = null) => {
  return async (dispatch, getState) => {
    const state = getState();
    //console.log("fetchTable state ", state);

    let config = state.WebSocket.config;
    let URL_WS = getURLAPI(config, "URL_WS"); //get address WebSocket BK from config
    try {
      //const response = await axios.get(`${API_URL}/brand/list`);

      let token = localStorage.getItem("token");
      let userId = localStorage.getItem("userId"); // "38ad21bd-13bb-4966-85d7-433c550b2f9d";

      let auth = {
        userId: userId,
        tocken: token,
      };
      try {
        //URL_WS = 'wss://shop.pegas-sk.com.ua:8043'

        let ws_client = new WebSocket(URL_WS);

        ws_client.on("close", function () {
          console.log("event : ws_client close !!!");
        });
        ws_client.on("error", (error) => {
          console.log("ws_client error", error);
        });

        ws_client.on("open", async function () {
          // login your client to be able to use protected methods
          ws_client
            .login(auth)
            .then(function (result) {
              //console.log('read from table Users where login passw : ', result)
              /*
              if (result.status === 200) {
                auth.login(result.data.token, result.data.userId)
              } else if (result.status === 400) {
                //err
                console.log('ERR login : ', result)
                setError(result.message)
              }
  */

              let options = params2.options;
              let tableName = params2.tableName;
              let action = params2.action;
              let typefetch = params2.typefetch;
              //read from table UnitTypes, where  id=1
              ws_client
                .call("Model_read", {
                  tableName,
                  where: options,
                  action,
                })
                .then(function ({ tableName, result }) {
                  //console.log('read from table Users where login passw : ',result)
                  if (result) {
                    result._id = result.id;
                    dispatch({
                      type: typefetch,
                      payload: result,
                    });

                    //auth.login(result.data.token, result.data.userId);
                  } else if (result.status === 400) {
                    //err
                    //setError(result.message);
                  }
                })
                .catch(function (error) {
                  console.log("err", error);
                  //setError(error);
                });

              //setLoading(false);
            })
            .catch(function (error) {
              console.log("auth failed - error : ", error);
              //setLoading(false);
              //setError(error);
            });
        });
      } catch (error) {
        console.log(error);
        //setLoading(false);
        //setError(error);
      }

      //**************** */
    } catch (error) {
      handleError(error, dispatch);
    }
  };
};
