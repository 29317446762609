/*
 *
 * Cart actions
 *
 */

//import { push } from 'connected-react-router';
//import { success } from "react-notification-system-redux";
//import axios from "axios";
//import { useSelector, useDispatch } from "react-redux";

import {
  HANDLE_CART,
  ADD_TO_CART,
  REMOVE_FROM_CART,
  REMOVE_ONE_FROM_CART,
  HANDLE_CART_TOTAL,
  SET_CART_ID,
  CLEAR_CART,
  TOGGLE_CART_STYLE,
} from "./constants";

import {
  SET_PRODUCT_SHOP_FORM_ERRORS,
  RESET_PRODUCT_SHOP,
} from "../Product/constants";

import { API_URL, CART_ID, CART_ITEMS, CART_TOTAL } from "../../constants";
import handleError from "../../utils/error";
//import { allFieldsValidation } from "../../utils/validation";
import { toggleCart } from "../Navigation/actions";

// Toast
import { toast } from "react-hot-toast";
import { wss_setItem } from "../WebSocket/actions";

export const toggleCartStyle = () => {
  return {
    type: TOGGLE_CART_STYLE,
  };
};

// Handle Add To Cart
export const handle_AddOneToCart = (product, cart) => {
  return (dispatch, getState) => {
    const existingProduct = cart.cartItems?.find((item) => {
      return product._id === item._id;
    });
    product.quantity = Number(
      existingProduct?.quantity ? existingProduct?.quantity + 1 : 1
    );
    product.totalPrice = product.quantity * product.price;
    product.totalPrice = parseFloat(product.totalPrice.toFixed(2));
    const inventory = 1111; //getState().product.storeProduct.inventory;

    const result = calculatePurchaseQuantity(inventory);

    const rules = {
      quantity: `min:1|max:${result}`,
    };

    /*
    const { isValid, errors } = allFieldsValidation(product, rules, {
      "min.quantity": "Quantity must be at least 1.",
      "max.quantity": `Quantity may not be greater than ${result}.`,
    });

    if (!isValid) {
      return dispatch({ type: SET_PRODUCT_SHOP_FORM_ERRORS, payload: errors });
    }
*/
    dispatch({
      type: RESET_PRODUCT_SHOP,
    });

    dispatch({
      type: ADD_TO_CART,
      payload: product,
    });

    const cartItems = JSON.parse(localStorage.getItem(CART_ITEMS));
    let newCartItems = [];
    if (cartItems) {
      const existingProduct = cartItems.find((item) => {
        return product._id === item._id;
      });
      if (existingProduct) {
        newCartItems = cartItems.map((item) => {
          if (item._id === existingProduct._id) {
            existingProduct.quantity += 1;
            existingProduct.totalPrice =
              existingProduct.quantity * existingProduct.price;
            existingProduct.totalPrice = parseFloat(
              existingProduct.totalPrice.toFixed(2)
            );
            return existingProduct;
          } else return item;
        });
      } else newCartItems = [...cartItems, product];
    } else {
      newCartItems.push(product);
    }
    localStorage.setItem(CART_ITEMS, JSON.stringify(newCartItems));

    toast.success("One product added to your cart.");
    dispatch(calculateCartTotal());
    //dispatch(toggleCart());
  };
};

// Handle Remove ONE From Cart
export const handle_RemoveOneFromCart = (product, cart) => {
  return (dispatch, getState) => {
    const existingProduct = cart.cartItems?.find((item) => {
      return product._id === item._id;
    });
    if (existingProduct?.quantity > 1)
      product.quantity = Number(existingProduct.quantity - 1);
    else {
      product.quantity = 0;
    }
    product.totalPrice = product.quantity * product.price;
    product.totalPrice = parseFloat(product.totalPrice.toFixed(2));
    const inventory = 1111; //getState().product.storeProduct.inventory;

    const result = calculatePurchaseQuantity(inventory);

    const rules = {
      quantity: `min:1|max:${result}`,
    };

    /*
    const { isValid, errors } = allFieldsValidation(product, rules, {
      "min.quantity": "Quantity must be at least 1.",
      "max.quantity": `Quantity may not be greater than ${result}.`,
    });

    if (!isValid) {
      return dispatch({ type: SET_PRODUCT_SHOP_FORM_ERRORS, payload: errors });
    }
*/
    dispatch({
      type: RESET_PRODUCT_SHOP,
    });

    dispatch({
      type: REMOVE_ONE_FROM_CART,
      payload: product,
    });

    const cartItems = JSON.parse(localStorage.getItem(CART_ITEMS));
    let newCartItems = cartItems;
    if (cartItems) {
      const existingProduct = cartItems.find((item) => {
        return product._id === item._id;
      });
      if (existingProduct) {
        newCartItems = cartItems.map((item) => {
          if (item._id === existingProduct._id) {
            existingProduct.quantity -= 1;
            existingProduct.totalPrice =
              existingProduct.quantity * existingProduct.price;
            existingProduct.totalPrice = parseFloat(
              existingProduct.totalPrice.toFixed(2)
            );
            return existingProduct;
          } else return item;
        });
        if (existingProduct.quantity <= 0) {
          newCartItems = cartItems.filter((item) => {
            return item._id !== existingProduct._id;
          });
        }
      }
    }
    localStorage.setItem(CART_ITEMS, JSON.stringify(newCartItems));

    toast.success("One product added to your cart.");
    dispatch(calculateCartTotal());
    //dispatch(toggleCart());
  };
};

// Handle Remove From Cart
export const handle_RemoveFromCart = (product) => {
  return (dispatch, getState) => {
    const cartItems = JSON.parse(localStorage.getItem(CART_ITEMS));
    const newCartItems = cartItems.filter((item) => item._id !== product._id);
    localStorage.setItem(CART_ITEMS, JSON.stringify(newCartItems));

    dispatch({
      type: REMOVE_FROM_CART,
      payload: product,
    });
    toast.success("One product removed from your cart.", { duration: 3000 });
    dispatch(calculateCartTotal());
    //dispatch(toggleCart());
  };
};

export const handleRemoveFromCart = (product) => {
  return (dispatch, getState) => {
    const cartItems = JSON.parse(localStorage.getItem(CART_ITEMS));
    const newCartItems = cartItems.filter((item) => item._id !== product._id);
    localStorage.setItem(CART_ITEMS, JSON.stringify(newCartItems));

    dispatch({
      type: REMOVE_FROM_CART,
      payload: product,
    });
    dispatch(calculateCartTotal());
    //dispatch(toggleCart());
  };
};

export const calculateCartTotal = () => {
  return (dispatch, getState) => {
    const cartItems = getState().cart.cartItems;

    let total = 0;

    cartItems.map((item) => {
      total += item.price * item.quantity;
    });

    total = parseFloat(total.toFixed(2));
    localStorage.setItem(CART_TOTAL, total);
    dispatch({
      type: HANDLE_CART_TOTAL,
      payload: total,
    });
  };
};

// set cart store from local storage
export const handle_Cart = (dispatch) => {
  const cart = {
    cartItems: JSON.parse(localStorage.getItem(CART_ITEMS)),
    cartTotal: localStorage.getItem(CART_TOTAL),
    cartId: localStorage.getItem(CART_ID),
  };

  return (dispatch, getState) => {
    if (cart?.cartItems != undefined) {
      dispatch({
        type: HANDLE_CART,
        payload: cart,
      });
      dispatch(calculateCartTotal());
    }
  };
};

export const handle_Checkout = (dispatch) => {
  return (dispatch, getState) => {
    const successfulOptions = {
      title: `Please Login to proceed to checkout`,
      position: "tr",
      autoDismiss: 1,
    };

    dispatch(toggleCart());
    //    dispatch(push('/login'));
    //dispatch(success(successfulOptions));
  };
};

// Continue shopping use case
export const handleShopping = () => {
  return (dispatch, getState) => {
    //dispatch(push("/shop"));
    dispatch(toggleCart());
  };
};

export const placeOrder = (dispatch) => {
  return (dispatch, getState) => {
    //dispatch(push("/shop"));
    dispatch(toggleCart());
  };
};

// create cart id api
export const getCartId = async (cartItems, dispatch) => {
  return async (dispatch, getState) => {
    const Save_Cart = function (_result) {
      const { tableName, result, readonly, action } = _result;

      if (tableName === "Carts") {
        localStorage.setItem(CART_ID, result.cartId);
        dispatch(setCartId(result.cartId));
      }
    };
    try {
      const cartId = localStorage.getItem(CART_ID);
      //    const cartItems = getState().cart.cartItems;
      const products = getCartItems(cartItems);

      // create cart id if there is no one
      if (!cartId) {
        let userId;

        const params = {
          tableName: "Carts",
          item: { items: products, userid: userId },
          call_nm: "Model_update",
          //where: { id: tov_item.id },
          action: { metod: "saveCart" },
          callback: Save_Cart,
        };

        dispatch(await wss_setItem(params));

        //const response = await axios.post(`${API_URL}/cart/add`, { products });

        //dispatch(setCartId(response.data.cartId));
      }
    } catch (error) {
      handleError(error, dispatch);
    }
  };
};

export const setCartId = (cartId, dispatch) => {
  return (dispatch, getState) => {
    localStorage.setItem(CART_ID, cartId);
    dispatch({
      type: SET_CART_ID,
      payload: cartId,
    });
  };
};

export const clearCart = (dispatch) => {
  return (dispatch, getState) => {
    localStorage.removeItem(CART_ITEMS);
    localStorage.removeItem(CART_TOTAL);
    localStorage.removeItem(CART_ID);

    dispatch({
      type: CLEAR_CART,
    });
  };
};

const getCartItems = (cartItems) => {
  const newCartItems = [];
  cartItems.map((item) => {
    const newItem = {};
    newItem.quantity = item.quantity;
    newItem.price = item.price;
    newItem.taxable = item.taxable;
    newItem.product = item._id;
    newCartItems.push(newItem);
  });

  return newCartItems;
};

const calculatePurchaseQuantity = (inventory) => {
  if (inventory <= 25) {
    return 1;
  } else if (inventory > 25 && inventory <= 100) {
    return 5;
  } else if (inventory > 100 && inventory < 500) {
    return 25;
  } else {
    return 50;
  }
};
