import { useEffect, useState } from 'react'

const useRemoteState = (remoteCallback, observedState) => {
  const [remoteState, setRemoteState] = useState(0)

  useEffect(() => {
    ;(async () => {
      const result = await remoteCallback(observedState)

      setRemoteState(result)
    })()
  }, [observedState])

  return remoteState
}

const readFileAsync = (file) => {
  return new Promise((resolve, reject) => {
    if (!file) {
      return
    }
    const reader = new FileReader()

    reader.onload = () => {
      resolve(`data:${file.type};base64,${btoa(reader.result)}`)
    }

    reader.onerror = reject

    reader.readAsBinaryString(file)
  })
}

const compact = (value) => (Array.isArray(value) ? value.filter(Boolean) : [])

const stringToPath = (input) =>
  compact(input.replace(/["|']|\]/g, '').split(/\.|\[/))

const isKey = (value) => /^\w*$/.test(value)

const isNullOrUndefined = (value) => value == null

const isDateObject = (value) => value instanceof Date

const isObjectType = (value) => typeof value === 'object'

const isObject = (value) =>
  !isNullOrUndefined(value) &&
  !Array.isArray(value) &&
  isObjectType(value) &&
  !isDateObject(value)

const isUndefined = (val) => val === undefined

const set = (object, path, value) => {
  let index = -1
  const tempPath = isKey(path) ? [path] : stringToPath(path)
  const length = tempPath.length
  const lastIndex = length - 1

  while (++index < length) {
    const key = tempPath[index]
    let newValue = value

    if (index !== lastIndex) {
      const objValue = object[key]
      newValue =
        isObject(objValue) || Array.isArray(objValue)
          ? objValue
          : !isNaN(+tempPath[index + 1])
          ? []
          : {}
    }
    object[key] = newValue
    object = object[key]
  }
  return object
}

const get = (obj, path, defaultValue) => {
  if (!path || !isObject(obj)) {
    return defaultValue
  }

  const result = compact(path.split(/[,[\].]+?/)).reduce(
    (result, key) => (isNullOrUndefined(result) ? result : result[key]),
    obj
  )

  return isUndefined(result) || result === obj
    ? isUndefined(obj[path])
      ? defaultValue
      : obj[path]
    : result
}

const useForm = (initState) => {
  const [remoteState, setRemoteState] = useState(initState)

  const setValue = (name, value) => {
    setRemoteState((prev) => {
      set(prev, name, value)
      return { ...prev }
    })
  }

  const setFileValue = async (name, file) => {
    const value = await readFileAsync(file)

    // setValue(name, value);
  }

  const getValue = (name, value) => get(remoteState, name, value)

  const register = (name, option) => {
    const onChange = (Event) => {
      let value
      console.log('register onChange Event ', Event)
      if (Event === null) {
        value = ''
      } else if (typeof Event === 'string') {
        value = Event
      } else if (Event.constructor && Event.constructor.name === 'Moment') {
        value = Event
      } else if (Event.target && Event.target.type === 'checkbox') {
        value = Event.target && Event.target.checked
      } else {
        value = (Event.target && Event.target.value) || Event
      }
      setValue(name, value)
      if (option && option.onChange) option.onChange(value)
    }

    const onBlur = (Event) => {
      const value = Event.target.value
      if (option && option.onBlur) option.onBlur(value)
    }

    const res = {
      onChange,
      onBlur,
      name,
    }

    const data = getValue(name)
    if (data && data.constructor && data.constructor.name === 'Moment') {
      res.value = data.format('YYYY-MM-DD HH:mm')
    } else if (option && option.type && option.type === 'checkbox') {
      res.value = data
    } else {
      res.value = data
    }

    // if (option && option.type && option.type === "radio")
    // res.checken = true;
    //console.log(name, res)
    return res
  }

  return { setValue, getValue, formState: remoteState, register }
}

export { useRemoteState, useForm }
