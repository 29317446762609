import { createStore, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";
//import { routerMiddleware } from 'connected-react-router'
import { createRouterMiddleware } from "../redux-react-router"; //'@lagunovsky/redux-react-router'
import { history, reducer } from "./reducers";
import rootSaga from "./sagas";

//import createLogger from 'redux-logger'
//const loggerMiddlewareR = createLogger()

import thunkMiddleware from "redux-thunk";
//import thunk from "redux-thunk";

import loggerMiddleware from "./middleware/logger";
//import monitorReducerEnhancer from './enhancers/monitorReducer'

//const middlewareEnhancer = applyMiddleware(loggerMiddleware, thunkMiddleware);
const middlewareEnhancer = applyMiddleware(thunkMiddleware);

//const composedEnhancers = compose(middlewareEnhancer,monitorReducerEnhancer)

const sagaMiddleware = createSagaMiddleware();

const composeEnhancers =
  typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
      })
    : compose;

const enhancer = composeEnhancers(
  applyMiddleware(sagaMiddleware, createRouterMiddleware(history))
);

const enhancer2 = composeEnhancers(
  applyMiddleware(sagaMiddleware, createRouterMiddleware(history)),
  middlewareEnhancer
);

const store = createStore(reducer, enhancer2);

//const store = createStore(reducer, enhancer) //, composedEnhancers)
/*
  applyMiddleware(
    thunkMiddleware, // lets us dispatch() functions
    loggerMiddlewareR // neat middleware that logs actions
  )
)
*/
sagaMiddleware.run(rootSaga, history);

export default store;
