import { Products } from "../../../types/Product";

import ProductCard from "../ProductCard2";
import {
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import Pagination from "@mui/material/Pagination";

//import styles from './ProductList.module.css';
import "./ProductList.module.css";
import "../ProductCard/hasan.css"; //ProductList.module.css";

interface Props {
  products: Products;
  handle_AddOneToCart:any;
  handle_RemoveOneFromCart:any;
isPr3: boolean;
}
/*
    <div
      className="grid gap-6 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 mt-4"
      data-test="product-list-container"
    >
      <div
        className="border border-gray-200 font-lato"
        data-test="product-card"
      >
      </div>

 */

const ProductList = (props: Props) => {
  const {
    products,
    handle_AddOneToCart,
    handle_RemoveOneFromCart,

    isPr3,
  } = props;

  return (
    <>
      {/*      <div
        className={"ProductList_productGrid"}
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          gridGap: "1.0rem",
          margin: "0rem 0",
        }}
        aria-label="Product list"
      >
        */}
      <div className="product-list">
        {products.map((product) => (
          <ProductCard
            product={product}
            handle_AddOneToCart={handle_AddOneToCart}
            handle_RemoveOneFromCart={handle_RemoveOneFromCart}
            key={product._id}
            isPr3={isPr3}
          />
        ))}
      </div>
      <div className="d-flex justify-content-center text-center mt-4">
        <div className="pagination-box">
          <ul className="pagination">
            <li className="page-item">
              <a
                className="page-link disabled"
                tabIndex={0}
                role="button"
                aria-disabled="false"
                aria-label="Previous page"
                rel="prev"
              >
                &lt; previous
              </a>
            </li>
            <li className="page-item  active">
              <a
                rel="prev"
                role="button"
                className="page-link"
                tabIndex={0}
                aria-label="Page 1"
                aria-current="page"
              >
                1
              </a>
            </li>
            <li className="page-item">
              <a
                rel="canonical"
                role="button"
                className="page-link"
                tabIndex={-1}
                aria-label="Page 2 is your current page"
              >
                2
              </a>
            </li>
            <li className="page-item">
              <a
                className="page-link "
                tabIndex={-1}
                role="button"
                aria-disabled="true"
                aria-label="Next page"
                rel="next"
              >
                next &gt;
              </a>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default ProductList;
