/** @jsxRuntime classic */
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
//import 'proxy-polyfill'
// IE11 needs "jsxRuntime classic" for this initial file which means that "React" needs to be in scope
// https://github.com/facebook/create-react-app/issues/9906

import * as React from "react";

//import ReactDOM from 'react-dom'

import App from "./App";

import { Provider } from "react-redux";

import store from "./redux";
/*
включение HTTPS 
.env  <= HTTPS=true

*/

//React 18
import { createRoot } from "react-dom/client";
//import "./index.css";

const container = document.getElementById("root");
const root = createRoot(container!); // createRoot(container!) if you use TypeScript
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);

/*
ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
)
*/
