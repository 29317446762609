/*
 *
 * NavigationMenu reducer
 *
 */

import {
  DEFAULT_ACTION,
  SET_OPENCATEGORY,
  SET_SELECTEDCATEGORY,
} from "./constants";

const initialState = {
  OpenCategory: null,
  SelectedCategory: null,
};

const navigationFilterReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_OPENCATEGORY:
      return {
        ...state,
        OpenCategory: action.payload,
      };
    case SET_SELECTEDCATEGORY:
      return {
        ...state,
        SelectedCategory: action.payload,
      };
    case DEFAULT_ACTION:
      return {
        ...state,
      };
    default:
      return state;
  }
};

export default navigationFilterReducer;
