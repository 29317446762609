/*
 *
 * Cart reducer
 *
 */

import {
  HANDLE_CART,
  ADD_TO_CART,
  REMOVE_FROM_CART,
  REMOVE_ONE_FROM_CART,
  HANDLE_CART_TOTAL,
  SET_CART_ID,
  CLEAR_CART,
  TOGGLE_CART_STYLE,
} from "./constants";

const initialState = {
  cartItems: [],
  cartTotal: 0,
  cartId: "",
  cartstyle: false,
};

const cartReducer = (state = initialState, action) => {
  let newState;

  switch (action.type) {
    case ADD_TO_CART:
      const existingProduct = state.cartItems?.find((product) => {
        return product._id === action.payload._id;
      });
      if (existingProduct) {
        state.cartItems = state.cartItems.map((item) => {
          if (item._id === existingProduct._id) {
            //existingProduct.quantity += 1;
            return action.payload; //existingProduct;
          } else return item;
        });
        newState = {
          ...state,
          cartItems: [...state.cartItems],
        };
      } else {
        //        newState = [...state, { ...action.payload, quantity: 1 }];
        newState = {
          ...state,
          cartItems: [...state.cartItems, action.payload],
        };
      }
      return newState;

    case TOGGLE_CART_STYLE:
      return {
        ...state,
        cartstyle: !state.cartstyle,
      };

    case REMOVE_ONE_FROM_CART:
      const existingProduct1 = state.cartItems?.find((product) => {
        return product._id === action.payload._id;
      });
      if (existingProduct1) {
        state.cartItems = state.cartItems.map((item) => {
          if (item._id === existingProduct1._id) {
            //existingProduct1.quantity -= 1;
            return action.payload; //existingProduct1;
          } else return item;
        });

        if (existingProduct1.quantity <= 0) {
          state.cartItems = state.cartItems.filter((item) => {
            return item._id !== existingProduct1._id;
          });
        }

        newState = {
          ...state,
          cartItems: [...state.cartItems],
        };
      } else {
        //        newState = [...state, { ...action.payload, quantity: 1 }];
        newState = {
          ...state,
          cartItems: [...state.cartItems, action.payload],
        };
      }
      return newState;
    case REMOVE_FROM_CART:
      let itemIndex = state.cartItems.findIndex(
        (x) => x._id == action.payload._id
      );

      newState = {
        ...state,
        cartItems: [
          ...state.cartItems.slice(0, itemIndex),
          ...state.cartItems.slice(itemIndex + 1),
        ],
      };

      return newState;
    case HANDLE_CART_TOTAL:
      newState = {
        ...state,
        cartTotal: action.payload,
      };

      return newState;
    case HANDLE_CART:
      newState = {
        ...state,
        cartItems: action.payload.cartItems,
        cartTotal: action.payload.cartTotal,
        cartId: action.payload.cartId,
      };
      return newState;
    case SET_CART_ID:
      newState = {
        ...state,
        cartId: action.payload,
      };
      return newState;
    case CLEAR_CART:
      newState = {
        ...state,
        cartItems: [],
        cartTotal: 0,
        cartId: "",
      };
      return newState;

    default:
      return state;
  }
};

export default cartReducer;
