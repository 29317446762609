/**
 *
 * Checkbox
 *
 */

import React, { useEffect, useState } from "react";

const List = (props) => {
  const { className, id, name, label, disabled, checked, onChange } = props;
  const [isChecked, setIsChecked] = useState(checked);

  const isLabelText = label && typeof label === "string";
  const extraClassName = isLabelText
    ? ` default-icon ${className}`
    : ` custom-icon ${className}`;

  return (
    <div className={`checkbox${extraClassName}`}>
      <list
        //className={'input-checkbox'}
        //type={'checkbox'}
        id={id}
        name={name}
        //checked={!disabled ? isChecked : false}
        //onChange={_onChange}
      />
      <label htmlFor={id} type="submit">
        {isLabelText ? label : label}
      </label>
    </div>
  );
};

export default List;

List.defaultProps = {
  className: "",
};
