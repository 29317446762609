const URL_API =
  process.env.NODE_ENV === "development"
    ? "http://localhost:3025"
    : "http://localhost:3025";

const URL_WS =
  process.env.NODE_ENV === "development"
    ? "ws://localhost:8040"
    : "ws://localhost:8040";

export { URL_API, URL_WS };
