import React, { useContext, useState, useCallback, useEffect } from "react";

import { Link } from "react-router-dom";
import { Form } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import Button from "@mui/material/Button";

import { connect } from "react-redux";
import actions from "../actions";

import ProductList from "../components/product/ProductList/ProductList";
import NotFound from "../components/Common/NotFound";
import LoadingIndicator from "../components/Common/LoadingIndicator";
//import Button from "../components/Common/Button";
import { BarsIcon } from "../components/Common/Icon";
import { CloseIcon } from "../components/Common/Icon";
import {
  ImageButton,
  ImageSrc,
  ImageBackdrop,
  Image,
  ImageMarked,
} from "../ext/ImageButton"; //material/Button";

import { Divider, IconButton } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import Check from "@mui/icons-material/Check";
import ListItemIcon from "@mui/material/ListItemIcon";
import List from "@mui/material/List";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import TreeView, { treeViewClasses } from "@mui/lab/TreeView";
import TreeItem, { useTreeItem, treeItemClasses } from "@mui/lab/TreeItem";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";

import FormControlLabel from "@mui/material/FormControlLabel";
import Switch, { SwitchProps } from "@mui/material/Switch";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import { styled as mstyled } from "@mui/material/styles";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import WarehouseIcon from "@mui/icons-material/Warehouse";

import { useTable } from "react-table";
import styled from "styled-components";
import { useMediaQuery } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Typography from "@material-ui/core/Typography";

import { Card, CardContent } from "@mui/material";
import { useTranslate } from "../lib/translate/i18n/useTranslate";
import { AuthContext } from "../context/AuthContext";
import { getURLAPI } from "../utils/getURL";

import { default as Spinner } from "../components/Spinner";

import GridTable from "../lib/react-grid-table/index";

import Paper from "@mui/material/Paper";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import Draggable from "react-draggable";

import { Fancybox } from "../ext/Fancybox";

import Filter from "../containers/Filter";
import Cart from "../containers/Cart";
//import "../containers/Cart/cart.css";
//import { handle_Cart } from "../containers/Cart/cartactions.js";

import { useForm } from "../hooks/remote";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const Styles = styled.div`
  table {
    border-spacing: 0;
    border: 1px solid #c3c3c3;
    border-radius: 10px;
    border-collapse: initial;
    tr {
      border-radius: 0px;

      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid #c3c3c3;
      border-right: 1px solid #c3c3c3;

      :last-child {
        border-right: 0;
      }
    }
  }
`;

const Styles2 = styled.div`
  table {
    border-spacing: 0;
    border: 1px solid black;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid black;
      border-right: 1px solid black;

      :last-child {
        border-right: 0;
      }
    }
  }
`;

var data = [];

const ProductVidList = (props) => {
  const {
    isCartOpen,
    toggleCart,
    isLoading,
    handle_Cart,
    categories,
    OpenCategory,
    SelectedCategory,
    set_OpenCategory,
    set_SelectedCategory,

    handle_AddOneToCart,
    handle_RemoveOneFromCart,
    isFilterOpen,
    toggleFilter,

    isViewOld,
    isViewNew,
    toggleViewOld,
    toggleViewNew,
  } = props;

  const app = useSelector((state) => state.app);
  const navigation = useSelector((state) => state.navigation);
  const cart = useSelector((state) => state.cart);
  const body = useSelector((state) => state.app.body);
  const access = useSelector((state) => state.app.access.access);
  const isadmin = (access && access.isadmin) || false;
  const isXSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const auth = useContext(AuthContext);
  const dispatch = useDispatch();

  //let isCartOpen = navigation.isCartOpen;
  //let isFilterOpen = navigation.isFilterOpen;

  /*  const TOGGLE_CART = "src/Navigation/TOGGLE_CART";
  const toggleCart2 = () => {
    dispatch({
      type: TOGGLE_CART,
    });
  };
*/
  useEffect(() => {
    try {
      handle_Cart();
    } catch (err) {
      console.log("deblock error ", err);
    }
  }, []);

  const nameTheme = access && access.user && access.user.theme;
  const translate = useTranslate();

  let borderbox = "1px solid black";
  if (nameTheme) {
    borderbox = nameTheme !== "dark" ? "1px solid black" : "1px solid grey";
  }

  var vids = [];

  const { formState, register, setValue } = useForm({
    //FancyboxFotos: null,
    maxWidth: "lg" /*  "xs"  "sm"  "md"  "lg"  "xl"  */,
    InCartOn: false,
    OptPriceOn: true,
    selectedfolder: null,
    startRefreshFilter: true,
    startRefresh: true,
    reload1: false,
    reload2: false,
  });

  if (formState.reload1 && !formState.startRefreshFilter && !body.vids) {
    console.log("1");
    setValue("reload1", false);
    setValue("startRefreshFilter", true);
  }
  if (formState.reload2 && !formState.startRefresh && !body.tovs) {
    console.log("22");
    setValue("reload2", false);
    setValue("startRefresh", true);
  }

  const handleChangeInCartOn = useCallback((event) => {
    setValue("InCartOn", !formState.InCartOn);
  });

  const handleChangeOptPriceOn = useCallback((event) => {
    setValue("OptPriceOn", !formState.OptPriceOn);
  });

  //START get all str from table Roles
  //console.log('Refresh!!! customers...')

  //замыкание
  const ws_client = auth.ws_client; //body.ws_client

  const Get_URL_API = function () {
    let result;
    let config = auth?.config?.config;
    for (let c in config) {
      if (config[c].type === "URL_API") result = config[c].url;
    }
    //console.log(result);
    return result;
  };
  let URL_API = Get_URL_API();
  //  const item_id = body.item_id

  //  const readonly = body.readonly

  const GetData_handle = function (_result) {
    const { tableName, result, readonly, action } = _result;
    //console.log('result handle1 ', result

    if (tableName === "Vts") {
      setValue("reload1", true);
      dispatch({
        type: "fetch/get_add", //'fetch/filter', //'fetch/get_add',
        payload: result,
        ob: "vids",
      });
    }

    if (tableName === "Tovs") {
      setValue("reload2", true);
      dispatch({
        type: "fetch/get_add", //'fetch/get_add',
        payload: result,
        ob: "tovs",
      });
    }
  };

  const Refresh_handle = async function (result, event, parent) {
    const { tableName, action, metod } = result;
    /*console.log(
      'Refresh_handle ',
      event,
      new Date().toLocaleTimeString(),
      result
    )*/
    setValue("startRefresh", true);
  };

  const paramsFilter = {
    URL_WS: getURLAPI(auth.config, "URL_WS"), //get address WebSocket BK from config
    auth: { userId: auth.userId, tocken: auth.token },
    handleGetData: GetData_handle,
    //handleChangeData: Refresh_handle,
    handleSetRefresh: () => {
      setValue("startRefreshFilter", true);
    },
    handleErrorRead: null,
    handleErrorChange: null,
    models: [
      {
        model_call_nm: "Model_read",
        tableName: "Vts",
        action: {
          metod: "get",
        },
      },
    ],
  };

  const params = {
    URL_WS: getURLAPI(auth.config, "URL_WS"), //get address WebSocket BK from config
    auth: { userId: auth.userId, tocken: auth.token },
    handleGetData: GetData_handle,
    //handleChangeData: Refresh_handle,
    handleSetRefresh: () => {
      setValue("startRefresh", true);
    },
    handleErrorRead: null,
    handleErrorChange: null,
    models: [
      {
        model_call_nm: "Model_read",
        tableName: "Tovs",
        action: {
          metod: "get",
        },
      },
    ],
  };

  // close page
  useEffect(
    () => () => {
      try {
        dispatch({
          type: "fetch/get",
          payload: true,
          ob: "close",
        });
      } catch (err) {
        console.log("deblock error ", err);
      }
    },
    []
  );

  if (ws_client && ws_client.ws_login && formState.startRefreshFilter) {
    setValue("startRefreshFilter", false);
    ws_client.open_process(paramsFilter);
  }

  if (ws_client && ws_client.ws_login && formState.startRefresh) {
    setValue("startRefresh", false);
    ws_client.open_process(params);
  }

  //END get all str from table Roles

  //**************************************************************** */

  const StyledTreeItemRoot = mstyled(TreeItem)(({ theme }) => ({
    color: theme.palette.text.secondary,
    [`& .${treeItemClasses.content}`]: {
      color: theme.palette.text.secondary,
      borderTopRightRadius: theme.spacing(2),
      borderBottomRightRadius: theme.spacing(2),
      paddingRight: theme.spacing(1),
      fontWeight: theme.typography.fontWeightMedium,
      "&.Mui-expanded": {
        fontWeight: theme.typography.fontWeightRegular,
      },
      "&:hover": {
        backgroundColor: theme.palette.action.hover,
      },
      "&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused": {
        backgroundColor: `var(--tree-view-bg-color, ${theme.palette.action.selected})`,
        color: "var(--tree-view-color)",
      },
      [`& .${treeItemClasses.label}`]: {
        fontWeight: "inherit",
        color: "inherit",
      },
    },
    [`& .${treeItemClasses.group}`]: {
      marginLeft: 25,
      [`& .${treeItemClasses.content}`]: {
        paddingLeft: theme.spacing(2),
      },
    },
  }));

  function StyledTreeItem(props) {
    const {
      bgColor,
      color,
      labelIcon: LabelIcon,
      labelInfo,
      labelText,
      Id,
      parentId,
      level,
      ...other
    } = props;

    const handleContextMenu = (event) => {
      event.preventDefault();
      setValue(
        "contextMenu",
        formState.contextMenu === null
          ? {
              mouseX: event.clientX + 2,
              mouseY: event.clientY - 6,
            }
          : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
            // Other native context menus might behave different.
            // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
            null
      );
    };

    const handleClose = () => {
      console.log("Menu Close click nodeId", Id);

      setValue("contextMenu", null);
    };

    const handleAddGroup = () => {
      //      alert('Добавляем группу складов')
      console.log("Menu AddGroup click nodeId", Id);
      setValue("contextMenu", null);
    };

    const handleDelGroup = () => {
      alert("Удаляем группу складов");
      console.log("Menu DelGroup click nodeId", Id);
      setValue("contextMenu", null);
    };

    const handleAddWarehouse = () => {
      //      alert('Добавляем новый склад')
      console.log("Menu AddWarehouse click nodeId", Id);
      setValue("contextMenu", null);
    };

    const handleEditWarehouse = () => {
      alert("Редактируем склад");
      console.log("Menu EditWarehouse click nodeId", Id);
      setValue("contextMenu", null);
    };

    return (
      <>
        <StyledTreeItemRoot
          label={
            <Box sx={{ display: "flex", alignItems: "center", p: 0.5, pr: 0 }}>
              <Box component={LabelIcon} color="inherit" sx={{ mr: 1 }} />
              <div
                //onContextMenu={handleContextMenu}
                style={{ cursor: "context-menu" }}
              >
                <Typography
                  variant="body2"
                  sx={{ fontWeight: "inherit", flexGrow: 1 }}
                >
                  {labelText}
                </Typography>
                <Typography
                  variant="caption"
                  color="inherit"
                  /*
                <Menu
                  open={formState.contextMenu !== null}
                  onClose={handleClose}
                  anchorReference="anchorPosition"
                  anchorPosition={
                    formState.contextMenu !== null
                      ? { top: formState.contextMenu.mouseY, left: formState.contextMenu.mouseX }
                      : undefined
                  }
                >
                  {(level === 0 || level === 1) && (
                    <>
                      <MenuItem
                        //onClick={handleAddGroup}
                        component={Link}
                        to="/pages/productvidfolders/item"
                        state={{
                          // id: Id, //cell.row.original['worktime_id'],
                          arg: {
                            parentid: Id,
                          },
                        }}
                      >
                        <ListItemIcon>
                          <AddCircleOutlineIcon fontSize="small" />
                        </ListItemIcon>
                        {translate('resources.productvid.add_group')}
                      </MenuItem>
                      <MenuItem
                        //onClick={handleDelGroup}
                        component={Link}
                        to="/pages/productvidfolders/item"
                        state={{
                          id: Id, //cell.row.original['worktime_id'],
                          //                         parentid: parentId,
                        }}
                      >
                        <ListItemIcon>
                          <Check fontSize="small" />
                        </ListItemIcon>
                        {translate('resources.productvid.edit_group')}
                      </MenuItem>
                    </>
                  )}
                  {level === 2 && (
                    <MenuItem
                      //onClick={handleDelGroup}
                      component={Link}
                      to="/pages/productvidfolders/item"
                      state={{
                        id: Id, //cell.row.original['worktime_id'],
                        //                         parentid: parentId,
                      }}
                    >
                      <ListItemIcon>
                        <Check fontSize="small" />
                      </ListItemIcon>
                      {translate('resources.productvid.edit_group')}
                    </MenuItem>
                  )}
                  {level !== 3 && (
                    <MenuItem
                      //                      onClick={handleAddWarehouse}
                      component={Link}
                      to="/pages/productvid/item"
                      state={{
                        // id: Id, //cell.row.original['worktime_id'],
                        arg: {
                          vtid: Id,
                          vtname: labelText,
                        },
                      }}
                    >
                      <ListItemIcon>
                        <AddCircleOutlineIcon fontSize="small" />
                      </ListItemIcon>
                      {translate('resources.productvid.add_productvid')}
                    </MenuItem>
                  )}
                  {level === 3 && (
                    <>
                      <MenuItem
                        //                      onClick={handleEditWarehouse}
                        component={Link}
                        to="/pages/productvid/item"
                        state={{
                          id: Id, //cell.row.original['worktime_id'],
                        }}
                      >
                        <ListItemIcon>
                          <Check fontSize="small" />
                        </ListItemIcon>
                        {translate('resources.productvid.edit_productvid')}
                      </MenuItem>
                    </>
                  )}
                </Menu>
                */
                >
                  {labelInfo}
                </Typography>
              </div>
            </Box>
          }
          style={{
            "--tree-view-color": color,
            "--tree-view-bg-color": bgColor,
          }}
          {...other}
        />
      </>
    );
  }

  StyledTreeItem.propTypes = {
    bgColor: PropTypes.string,
    color: PropTypes.string,
    labelIcon: PropTypes.elementType.isRequired,
    labelInfo: PropTypes.string,
    labelText: PropTypes.string.isRequired,
    Id: PropTypes.string,
    level: PropTypes.string,
  };

  const productvidfolders = body.vids; //app.filter.productvidfolders_filter
  let folders = [
    {
      id: -1,
      name: "<Все>",
      fchild: [],
      wchild: [],
    },
  ];

  for (var f0 in productvidfolders) {
    if (!productvidfolders[f0].parentid)
      folders.push({
        id: productvidfolders[f0].id,
        name: productvidfolders[f0].name,
        fchild: [],
        wchild: [],
      });
  }

  for (var f1 in productvidfolders) {
    if (productvidfolders[f1].parentid)
      for (var fo1 in folders) {
        if (folders[fo1].id === productvidfolders[f1].parentid) {
          folders[fo1].fchild.push({
            id: productvidfolders[f1].id,
            name: productvidfolders[f1].name,
            fchild: [],
            wchild: [],
          });
        }
      }
  }

  for (var f2 in productvidfolders) {
    if (productvidfolders[f2].parentid)
      for (var fo2 in folders) {
        if (folders[fo2].fchild.length > 0) {
          for (var ch1 in folders[fo2].fchild) {
            if (
              folders[fo2].fchild[ch1].id === productvidfolders[f2].parentid
            ) {
              folders[fo2].fchild[ch1].fchild.push({
                id: productvidfolders[f2].id,
                name: productvidfolders[f2].name,
                fchild: [],
                wchild: [],
              });
            }
          }
        }
      }
  }

  //************************************************************** */

  data = [];

  let num = 1;
  let productvids = body.tovs; //productvid

  if (formState.selectedfolder) {
    productvids = productvids?.filter((item) => {
      //        console.log(item)

      return (
        (item.id === formState.selectedfolder.item.id &&
          formState.selectedfolder.level === 3) ||
        (item.vtid === formState.selectedfolder.item.id &&
          formState.selectedfolder.level === 1) ||
        (item.vts.parentid === formState.selectedfolder.item.id &&
          formState.selectedfolder.level === 0) ||
        formState.selectedfolder.item.id === -1 ||
        false
      );

      //        return (
      //          item.unittypeid === unittypeid || unittypeid === null //&&
      //      (findTypeWorks(item, typeworkid) || typeworkid === null) &&
      //      (findWorks(item, workid) || workid === null) &&
      //      (item.customersid === customerid || customerid === null)
      //        )
    });
  }

  if (productvids) {
    /*
    console.log('vid2', vid2)
    console.log('customer2', customer2)
    console.log('date1', date1)
    console.log('date2', date2)
    console.log('workerid', workerid)
    console.log('customerid', customerid)
    //console.log(customer2['value']['0'].value)
    //workerid = null
    // customerid = null
    */
    if (formState.filter)
      productvids = productvids.filter((item) => {
        //        console.log('item', item)
        //        console.log('item.name', item.name)
        return (
          (item.name &&
            item.name.toLowerCase().includes(formState.filter.toLowerCase())) ||
          (item.lastName &&
            item.lastName
              .toLowerCase()
              .includes(formState.filter.toLowerCase())) ||
          (item.tel1 &&
            item.tel1.toLowerCase().includes(formState.filter.toLowerCase())) ||
          (item.tel2 &&
            item.tel2.toLowerCase().includes(formState.filter.toLowerCase())) ||
          (item.comments &&
            item.comments
              .toLowerCase()
              .includes(formState.filter.toLowerCase())) ||
          false
        );
        //[item.name].contains('filter')
      });
  }
  const get_price = (prices, plevelid) => {
    let price = "0";
    for (let i in prices) {
      if (prices[i].plevelid === plevelid) price = prices[i].price;
    }
    price = parseInt(price);

    return price;
  };

  for (var iw in productvids) {
    let incart = cart?.cartItems?.find(
      (element) => element._id === productvids[iw].id
    );
    let fotos = productvids[iw].fotos;
    let isfoto = !fotos || fotos?.length > 0;
    let urlfoto = "";
    let urlthumbs = "";
    if (isfoto && fotos?.length > 0) {
      //console.log(fotos);
      urlfoto =
        URL_API +
        "/files" +
        "?dirname=" +
        fotos[0].dirname +
        "&name=" +
        fotos[0].filename;
      urlthumbs =
        URL_API +
        "/files" +
        "?dirname=.thumbs/" +
        fotos[0].dirname +
        "&name=" +
        fotos[0].filename;
    }
    if (!formState.InCartOn || (formState.InCartOn && incart))
      data.push({
        num: num++,
        _id: productvids[iw].id,
        name: productvids[iw].name,
        art: productvids[iw].art,
        price: formState.OptPriceOn
          ? productvids[iw].prices && get_price(productvids[iw].prices, "5")
          : productvids[iw].prices && get_price(productvids[iw].prices, "4"), //опт 3
        //      price2: productvids[iw].prices && get_price(productvids[iw].prices, "4"), //опт 2
        incart: incart,
        brand: {
          name: productvids[iw].brands.name,
          id: productvids[iw].brands.id,
          isActive: true,
        },
        category: {
          name: productvids[iw].categories.name,
          id: productvids[iw].categories.id,
        },

        description: productvids[iw].cmnt,
        parentfolders:
          productvids[iw].vts &&
          productvids[iw].vts.parent &&
          productvids[iw].vts.parent.name,
        folders: productvids[iw].vts && productvids[iw].vts.name,
        imageURL: urlfoto,
        thumbsURL: urlthumbs,
        fotos: productvids[iw].fotos,
        aspectratio: productvids[iw].fotos
          ? productvids[iw].fotos[0]?.aspectratio
          : null,
        comments: productvids[iw].cmnt,
        productvid: productvids[iw],
      });
  }

  for (var i in productvidfolders) {
    vids.push({
      label: productvidfolders[i].parentid
        ? "*   " + productvidfolders[i].name
        : productvidfolders[i].name,
      value: productvidfolders[i].id,
      level: productvidfolders[i].parentid ? 1 : 0,
    });
  }

  let displayProducts = data && data.length > 0;

  function Filter(props) {
    const { ...other } = props;
    return (
      <>
        <div
          className="navigation-menu"
          style={{
            //borderSpacing: 0,
            //border: borderbox,
            marginTop: 30,
            padding: 10,
          }}
        >
          <div className="menu-body">
            <div
              className="menu-header"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <h3 className="menu-title">Фильтр</h3>
              {isFilterOpen && (
                <>
                  <Button
                    //borderless
                    //variant="empty"
                    //ariaLabel="close the menu"
                    //icon={<CloseIcon />}
                    //onClick={toggleFilter}
                    onClick={() => toggleFilter()}
                  >
                    <CloseIcon />
                  </Button>
                </>
              )}
            </div>
            <div
              style={{
                borderSpacing: 0,
                border: borderbox,
                //marginTop: 60,
                padding: 10,
              }}
            >
              <TreeView
                aria-label="file system navigator"
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpandIcon={<ChevronRightIcon />}
                sx={{
                  height: 480,
                  flexGrow: 1,
                  width: "100%",
                  //                maxWidth: 500,
                  overflowY: "auto",
                }}
              >
                {folders.map((line0) => (
                  <StyledTreeItem
                    //ustomTreeItem
                    //StyledTreeItem

                    nodeId={line0.id.toString()}
                    key={line0.id.toString()}
                    Id={line0.id.toString()}
                    parentId={line0.parentid && line0.parentid.toString()}
                    //level={0}
                    labelText={line0.name}
                    labelIcon={FolderOpenIcon}
                    onClick={(item) => {
                      //                    console.log('onClick id', line0.id)
                      setValue("selectedfolder", {
                        level: 0,
                        item: line0,
                      });
                    }}
                    //                  onClick={(item) => {
                    //                    console.log('onClick id', line0.id)
                    //                  }}
                    /*                showbutton={true}
                handleadd={(item) => {
                  handleAdd(line0.id)
                }}
                handledel={(item) => {
                  handleDel(line0.id)
                }}
                */
                  >
                    {line0.fchild &&
                      line0.fchild.map((line1) => (
                        <StyledTreeItem
                          //CustomTreeItem
                          //StyledTreeItem
                          nodeId={line1.id.toString()}
                          key={line1.id.toString()}
                          Id={line1.id.toString()}
                          parentId={line1.parentid && line1.parentid.toString()}
                          //level={1}
                          labelText={line1.name}
                          labelIcon={FolderOpenIcon}
                          onClick={(item) => {
                            //                    console.log('onClick id', line0.id)
                            setValue("selectedfolder", {
                              level: 1,
                              item: line1,
                            });
                          }}
                          //                        onClick={(item) => {
                          //                          console.log('onClick id', line1.id)
                          //                        }}
                          /*                      showbutton={true}
                      handleadd={(item) => {
                        handleAdd(line1.id)
                      }}
                      handledel={(item) => {
                        handleDel(line1.id)
                      }}
                      */
                        >
                          {line1.fchild &&
                            line1.fchild.map((line2) => (
                              <>
                                <StyledTreeItem
                                  //CustomTreeItem
                                  //StyledTreeItem
                                  nodeId={line2.id.toString()}
                                  key={line2.id.toString()}
                                  Id={line2.id.toString()}
                                  parentId={line2.parentid}
                                  //level={2}
                                  labelText={line2.name}
                                  labelIcon={FolderOpenIcon}
                                  onClick={(item) => {
                                    //                    console.log('onClick id', line0.id)
                                    setValue("selectedfolder", {
                                      level: 2,
                                      item: line2,
                                    });
                                  }}
                                  //                                onClick={(item) => {
                                  //                                  console.log('onClick id', line2.id)
                                  //                                }}
                                >
                                  {line2.wchild &&
                                    line2.wchild.map((line3) => (
                                      <StyledTreeItem
                                        //CustomTreeItem
                                        //StyledTreeItem
                                        nodeId={line3.id.toString()}
                                        key={line3.id.toString()}
                                        Id={line3.id.toString()}
                                        parentId={line3.parentid}
                                        //level={3}
                                        labelText={line3.art + " " + line3.name}
                                        labelIcon={WarehouseIcon}
                                        onClick={(item) => {
                                          //                    console.log('onClick id', line0.id)
                                          setValue("selectedfolder", {
                                            level: 3,
                                            item: line3,
                                          });
                                        }}
                                        //                                      onClick={(item) => {
                                        //                                        console.log('onClick id', line3.id)
                                        //                                      }}
                                      ></StyledTreeItem>
                                    ))}
                                </StyledTreeItem>
                              </>
                            ))}
                          {line1.wchild &&
                            line1.wchild.map((wline2) => (
                              <StyledTreeItem
                                //CustomTreeItem
                                //StyledTreeItem
                                nodeId={wline2.id.toString()}
                                key={wline2.id.toString()}
                                Id={wline2.id.toString()}
                                //level={3}
                                labelText={wline2.art + " " + wline2.name}
                                labelIcon={WarehouseIcon}
                                onClick={(item) => {
                                  //                    console.log('onClick id', line0.id)
                                  setValue("selectedfolder", {
                                    level: 3,
                                    item: wline2,
                                  });
                                }}
                                //                              onClick={(item) => {
                                //                                console.log('onClick id', wline2.id)
                                //                              }}
                              ></StyledTreeItem>
                            ))}
                        </StyledTreeItem>
                      ))}
                    {line0.wchild &&
                      line0.wchild.map((wline1) => (
                        <StyledTreeItem
                          //CustomTreeItem
                          //StyledTreeItem
                          nodeId={wline1.id.toString()}
                          key={wline1.id.toString()}
                          Id={wline1.id.toString()}
                          //level={3}
                          labelText={wline1.art + " " + wline1.name}
                          labelIcon={WarehouseIcon}
                          onClick={(item) => {
                            //                    console.log('onClick id', line0.id)
                            setValue("selectedfolder", {
                              level: 3,
                              item: wline1,
                            });
                          }}
                          //                        onClick={(item) => {
                          //                          console.log('onClick id', wline1.id)
                          //                        }}
                        ></StyledTreeItem>
                      ))}
                  </StyledTreeItem>
                ))}
              </TreeView>
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <div
        style={{
          marginTop: 0,
          display: "flex",
          //justifyContent: "space-between",
        }}
      >
        <div className="header-links">
          <Button
            //borderless
            //variant="empty"
            //ariaLabel="open the filtermenu"
            //icon={<BarsIcon />}
            onClick={() => toggleFilter()}
          >
            <BarsIcon />
          </Button>
          <div>{formState.selectedfolder?.item?.name}</div>
        </div>

        <TextField
          //id="outlined-basic"
          label="Поиск "
          variant="outlined"
          defaultValue={formState.filter}
          style={{
            //marginLeft: 10,
            marginBottom: 10,
            width: 150,
          }}
          onChange={(item) => {
            setValue("filter", item.target.value);
          }}
        />

        <Button
          size="small"
          //                        component={Link}
          color="primary"
          variant={formState.InCartOn ? "contained" : "outlined"}
          sx={{
            height: 40,
            marginLeft: 2,
            marginTop: 1,
            display: "inline-flex",
            alignItems: "center",
          }}
          onClick={() => {
            handleChangeInCartOn();
          }}
        >
          В корзине
        </Button>

        <Button
          size="small"
          //                        component={Link}
          color="primary"
          variant={formState.OptPriceOn ? "contained" : "outlined"}
          sx={{
            height: 40,
            marginLeft: 2,
            marginTop: 1,
            display: "inline-flex",
            alignItems: "center",
          }}
          onClick={() => {
            handleChangeOptPriceOn();
          }}
        >
          Цена опт/розн
        </Button>
      </div>

      <Box
        style={{
          maxHeight: "680px",
          overflow: "auto",
          marginTop: 10,
        }}
      >
        {isLoading && <LoadingIndicator />}
        {displayProducts && (
          <ProductList
            products={data}
            handle_AddOneToCart={handle_AddOneToCart}
            handle_RemoveOneFromCart={handle_RemoveOneFromCart}
            isPR3={formState.OptPriceOn}
          />
        )}
        {!isLoading && !displayProducts && (
          <NotFound message="No products found." />
        )}
      </Box>
      {/*      <FancyboxDialog /> */}
      {/* hidden cart drawer */}
      <div
        className={isCartOpen ? "mini-cart-open" : "hidden-mini-cart"}
        aria-hidden={isCartOpen ? false : true}
      >
        <div className="mini-cart" style={{ width: isXSmall ? "85%" : 500 }}>
          <Cart />
        </div>
        <div
          className={
            isCartOpen ? "drawer-backdrop dark-overflow" : "drawer-backdrop"
          }
          onClick={toggleCart}
        />
      </div>
      {/* hidden filter drawer */}
      <div
        className={isFilterOpen ? "mini-menu-open" : "hidden-mini-menu"}
        aria-hidden={`${isFilterOpen ? false : true}`}
      >
        <div className="mini-menu" style={{ width: isXSmall ? "85%" : 500 }}>
          {/*          <Filter /> */}
          <div
            //className="navigation-menu"
            style={{
              //borderSpacing: 0,
              //border: borderbox,
              marginTop: 30,
              padding: 10,
            }}
          >
            <div className="menu-body">
              <div
                className="menu-header"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: 20,
                }}
              >
                <h3 className="menu-title">Фильтр</h3>
                <Button
                  //borderless
                  //variant="empty"
                  //ariaLabel="open the filtermenu"
                  //icon={<CloseIcon />}
                  onClick={() => toggleFilter()}
                >
                  <CloseIcon />
                </Button>
              </div>
              <div
                style={{
                  borderSpacing: 0,
                  border: borderbox,
                  //marginTop: 60,
                  padding: 10,
                }}
              >
                <TreeView
                  aria-label="file system navigator"
                  defaultCollapseIcon={<ExpandMoreIcon />}
                  defaultExpandIcon={<ChevronRightIcon />}
                  sx={{
                    height: 420,
                    flexGrow: 1,
                    width: "100%",
                    //                maxWidth: 500,
                    overflowY: "auto",
                  }}
                >
                  {folders.map((line0) => (
                    <StyledTreeItem
                      //ustomTreeItem
                      //StyledTreeItem

                      nodeId={line0.id.toString()}
                      key={line0.id.toString()}
                      Id={line0.id.toString()}
                      parentId={line0.parentid && line0.parentid.toString()}
                      //level={0}
                      labelText={line0.name}
                      labelIcon={FolderOpenIcon}
                      onClick={(item) => {
                        //                    console.log('onClick id', line0.id)
                        setValue("selectedfolder", {
                          level: 0,
                          item: line0,
                        });
                      }}
                      //                  onClick={(item) => {
                      //                    console.log('onClick id', line0.id)
                      //                  }}
                      /*                showbutton={true}
                handleadd={(item) => {
                  handleAdd(line0.id)
                }}
                handledel={(item) => {
                  handleDel(line0.id)
                }}
                */
                    >
                      {line0.fchild &&
                        line0.fchild.map((line1) => (
                          <StyledTreeItem
                            //CustomTreeItem
                            //StyledTreeItem
                            nodeId={line1.id.toString()}
                            key={line1.id.toString()}
                            Id={line1.id.toString()}
                            parentId={
                              line1.parentid && line1.parentid.toString()
                            }
                            //level={1}
                            labelText={line1.name}
                            labelIcon={FolderOpenIcon}
                            onClick={(item) => {
                              //                    console.log('onClick id', line0.id)
                              setValue("selectedfolder", {
                                level: 1,
                                item: line1,
                              });
                            }}
                            //                        onClick={(item) => {
                            //                          console.log('onClick id', line1.id)
                            //                        }}
                            /*                      showbutton={true}
                      handleadd={(item) => {
                        handleAdd(line1.id)
                      }}
                      handledel={(item) => {
                        handleDel(line1.id)
                      }}
                      */
                          >
                            {line1.fchild &&
                              line1.fchild.map((line2) => (
                                <>
                                  <StyledTreeItem
                                    //CustomTreeItem
                                    //StyledTreeItem
                                    nodeId={line2.id.toString()}
                                    key={line2.id.toString()}
                                    Id={line2.id.toString()}
                                    parentId={line2.parentid}
                                    //level={2}
                                    labelText={line2.name}
                                    labelIcon={FolderOpenIcon}
                                    onClick={(item) => {
                                      //                    console.log('onClick id', line0.id)
                                      setValue("selectedfolder", {
                                        level: 2,
                                        item: line2,
                                      });
                                    }}
                                    //                                onClick={(item) => {
                                    //                                  console.log('onClick id', line2.id)
                                    //                                }}
                                  >
                                    {line2.wchild &&
                                      line2.wchild.map((line3) => (
                                        <StyledTreeItem
                                          //CustomTreeItem
                                          //StyledTreeItem
                                          nodeId={line3.id.toString()}
                                          key={line3.id.toString()}
                                          Id={line3.id.toString()}
                                          parentId={line3.parentid}
                                          //level={3}
                                          labelText={
                                            line3.art + " " + line3.name
                                          }
                                          labelIcon={WarehouseIcon}
                                          onClick={(item) => {
                                            //                    console.log('onClick id', line0.id)
                                            setValue("selectedfolder", {
                                              level: 3,
                                              item: line3,
                                            });
                                          }}
                                          //                                      onClick={(item) => {
                                          //                                        console.log('onClick id', line3.id)
                                          //                                      }}
                                        ></StyledTreeItem>
                                      ))}
                                  </StyledTreeItem>
                                </>
                              ))}
                            {line1.wchild &&
                              line1.wchild.map((wline2) => (
                                <StyledTreeItem
                                  //CustomTreeItem
                                  //StyledTreeItem
                                  nodeId={wline2.id.toString()}
                                  key={wline2.id.toString()}
                                  Id={wline2.id.toString()}
                                  //level={3}
                                  labelText={wline2.art + " " + wline2.name}
                                  labelIcon={WarehouseIcon}
                                  onClick={(item) => {
                                    //                    console.log('onClick id', line0.id)
                                    setValue("selectedfolder", {
                                      level: 3,
                                      item: wline2,
                                    });
                                  }}
                                  //                              onClick={(item) => {
                                  //                                console.log('onClick id', wline2.id)
                                  //                              }}
                                ></StyledTreeItem>
                              ))}
                          </StyledTreeItem>
                        ))}
                      {line0.wchild &&
                        line0.wchild.map((wline1) => (
                          <StyledTreeItem
                            //CustomTreeItem
                            //StyledTreeItem
                            nodeId={wline1.id.toString()}
                            key={wline1.id.toString()}
                            Id={wline1.id.toString()}
                            //level={3}
                            labelText={wline1.art + " " + wline1.name}
                            labelIcon={WarehouseIcon}
                            onClick={(item) => {
                              //                    console.log('onClick id', line0.id)
                              setValue("selectedfolder", {
                                level: 3,
                                item: wline1,
                              });
                            }}
                            //                        onClick={(item) => {
                            //                          console.log('onClick id', wline1.id)
                            //                        }}
                          ></StyledTreeItem>
                        ))}
                    </StyledTreeItem>
                  ))}
                </TreeView>
              </div>
            </div>
          </div>
        </div>
        <div
          className={
            isFilterOpen ? "drawer-backdrop dark-overflow" : "drawer-backdrop"
          }
          onClick={toggleFilter}
        />
      </div>
    </>
  );
};

//export default ProductVidList;
const mapStateToProps = (state) => {
  return {
    isLoading: state.app.body.tovs === undefined, //state.product?.isLoading,
    isCartOpen: state.navigation.isCartOpen,
    isFilterOpen: state.navigation.isFilterOpen,
    isViewOld: state.navigation.isViewOld,
    isViewNew: state.navigation.isViewNew,
    categories: state.category?.storeCategories,
    OpenCategory: state.filter.OpenCategory,
    SelectedCategory: state.filter.SelectedCategory,
  };
};

export default connect(mapStateToProps, actions)(ProductVidList);
