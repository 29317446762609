/**
 *
 * CartSummary
 *
 */

import React from "react";

//import { Container, Row, Col } from 'reactstrap';

const CartSummary = (props) => {
  const { cartTotal, discount } = props;

  return (
    <div className="cart-summary">
      <div className="container">
        <div className="mb-2 summary-item row">
          <div xs="3" className="text-right col-6">
            <span
              className="fs-5  price fw-bold"
              onClick={() => {
                alert("Скидка");
              }}
            >
              {"Скидка   "}
            </span>
            <span className="fs-5  price fw-bold">
              {discount ? discount + " грн." : "0 грн."}
            </span>
          </div>
          <div xs="3" className="col-3">
            <span className="fs-5  price fw-bold">Всего</span>
          </div>
          <div xs="3" className="text-right col-3">
            <span className="fs-5  price fw-bold">{cartTotal + " грн."}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CartSummary;
