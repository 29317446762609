import React, { useEffect } from "react";
//import { Routes, Route } from 'react-router'
import { useDispatch } from "react-redux";
import polyglotI18nProvider from "./lib/translate/i18n_polyglot/index";
import { Routes, Route } from "react-router-dom";

import {
  Link,
  //,
  //  useLocation,
  Navigate,
  //  Outlet,
  //  useNavigate,
  //  unstable_HistoryRouter as HistoryRouter,
} from "react-router-dom";

import { IconButton, Button, Avatar } from "@mui/material";
import AccountCircle from "@mui/icons-material/AccountCircle";

// Toast
import { Toaster } from "react-hot-toast";

import {
  ReduxRouter,
  //  ReduxRouterSelector,
  //  ReduxRouterState,
} from "./redux-react-router"; //'@lagunovsky/redux-react-router'

//const routerSelector: ReduxRouterSelector = (state: State) => state.router
//import store from './redux'

import { Container } from "react-bootstrap";

import { history } from "./redux/reducers";

import Home from "./pages/home";

import CustomersList from "./pages/pages/customers/list";
import CustomersItem from "./pages/pages/customers/item";

import ProductVidList from "./pages/pages/productvid/list";
import ProductVidItem from "./pages/pages/productvid/item";

import OrderList from "./pages/pages/orders/list";
import OrderItem from "./pages/pages/orders/item";

import HeadList from "./pages/pages/heads/list";
import HeadItem from "./pages/pages/heads/item";

import SupervisorList from "./pages/pages/supervisors/list";
import SupervisorItem from "./pages/pages/supervisors/item";

import ManagerList from "./pages/pages/managers/list";
import ManagerItem from "./pages/pages/managers/item";

import StoreList from "./pages/pages/stores/list";
import StoreItem from "./pages/pages/stores/item";

import RouteList from "./pages/pages/routes/list";
import RouteItem from "./pages/pages/routes/item";

import TovUnitList from "./pages/pages/tovunits/list";
import TovUnitItem from "./pages/pages/tovunits/item";

import TovList from "./pages/pages/tovs/list";
import TovItem from "./pages/pages/tovs/item";

import TypePriceList from "./pages/pages/typeprices/list";
import TypePriceItem from "./pages/pages/typeprices/item";

import PriceList from "./pages/pages/prices/list";
import PriceItem from "./pages/pages/prices/item";

import FormPayList from "./pages/pages/formpays/list";
import FormPayItem from "./pages/pages/formpays/item";

import VidPayList from "./pages/pages/vidpays/list";
import VidPayItem from "./pages/pages/vidpays/item";

import { AuthContext } from "./context/AuthContext";
import { useAuth } from "./hooks/auth.hook";

import { default as Loader } from "./components/Spinner";

import { LogoutPage } from "./pages/auth/LogoutPage";

import AuthList from "./pages/auth/list";
import AuthItem from "./pages/auth/item";

import ProfilePage from "./pages/auth/ProfilePage";
import ConfigPage from "./pages/pages/config/ConfigPage";
import RoleItem from "./pages/auth/role/item";

import RoleList from "./pages/auth/role/list";
import UnitList from "./pages/auth/unit/list";
import UnitItem from "./pages/auth/unit/item";
import UnitTypeList from "./pages/auth/unittype/list";
import UnitTypeItem from "./pages/auth/unittype/item";
import SessionList from "./pages/auth/session/list";
//import SessionItem from './pages/auth/session/item'

import Workers from "./pages/pages/workers/list";
import WorkersItem from "./pages/pages/workers/item";

import CronTasksList from "./pages/auth/crontasks/list";
import CronTasksItem from "./pages/auth/crontasks/item";

import RabbitMQList from "./pages/auth/rabbitmq/list";
import RabbitMQItem from "./pages/auth/rabbitmq/item";

import MyLogin from "./pages/auth/Login";

import { useSelector } from "react-redux";
import { Layout as MyLayout } from "./layouts/Layout";
import { lightTheme, darkTheme } from "./layouts/Them/themes";
import { defaultTheme } from "./layouts/Them/defaultTheme";

import { URL_API } from "./globals";
import { getURLAPI, getBASEURL } from "./utils/getURL";

import { wss_get_access } from "./utils/get_access";

import { I18nContextProvider } from "./lib/translate/i18n/I18nContextProvider";

//import englishMessages from './i18n/en'
import ukraineMessages from "./i18n/ua";

//import "./styles/style.scss";
// Import application sass styles
import "./styles/style.scss";

// Import Font Awesome Icons Set
import "font-awesome/css/font-awesome.min.css";

// Import Simple Line Icons Set
import "simple-line-icons/css/simple-line-icons.css";

// react-bootstrap-table2 styles
//import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";

// rc-slider style
//import "rc-slider/assets/index.css";

const i18nProvider = polyglotI18nProvider((locale) => {
  if (locale === "ru") {
    return import("./i18n/ru").then((messages) => messages.default);
  }
  if (locale === "en") {
    return import("./i18n/en").then((messages) => messages.default);
  }

  // Always fallback on ukraine
  return ukraineMessages;
}, "ua");

//type State = { router: ReduxRouterState }

//APP1
export const App = () => {
  //  const navigate = useNavigate()
  function NoMatch() {
    return (
      <div>
        <h2>Неверная ссылка!</h2>
        <p>
          <Link to="/">Перейти к основной странице</Link>
        </p>
      </div>
    );
  }

  const {
    token,
    login,
    logout,
    userId,
    ready,
    WorkDate,
    setdt,
    StartDate,
    setStartDate,
    EndDate,
    setEndDate,
    Worker,
    setWorker,
    Worker2,
    setWorker2,
    Customer,
    setCustomer,
    homeType,
    sethomeType,
    dispatchAccess,
    setdispatchAccess,
    config,
    setConfig,
    isRead,
    setIsRead,
    ws_client,
    IP,
    setIP,
  } = useAuth();
  const isAuthenticated = !!token;

  if (!isRead) {
    //if (!config) {
    setIsRead(true);
    //get config.json from baseURI
    //let baseurl = getBASEURL()
    const url = getBASEURL() + "/config.json"; //document.baseURI + 'config.json'
    //console.log('url', url)
    try {
      fetch(url) //document.baseURI + 'config.json')
        .then((res) => {
          //console.log('res', res)
          let rez;
          try {
            rez = res.json();
          } catch (err) {
            console.log(err);
          }
          return rez;
        }) // Gets the response
        .then((rs) => {
          //        console.log('result config ', rs)
          if (!config && rs) {
            setConfig(rs);
          }
        });
    } catch (err) {
      console.log(err);
      let conf = {
        config: [
          {
            type: "URL_API",
            url: URL_API,
          },
        ],
      };
      setConfig(conf);
    }
    // return <Loader />

    /*
    //ip
    const urlapigetip = "https://api.ipify.org/?format=json";
    try {
      fetch(urlapigetip)
        .then((res) => {
          let rez;
          try {
            rez = res.json();
          } catch (err) {
            console.log(err);
          }
          return rez;
        }) // Gets the response
        .then((rs) => {
          //        console.log('result config ', rs)
          setIP(rs);
        })
        .catch((err) => {
          //        console.log('result config ', rs)
          setIP({ ip: "0.0.0.1" });
        });
    } catch (err) {
      console.log(err);
      //setConfig(conf)
    }
*/
  }

  let URLAPI;
  let URL_WS;

  const connectStatus =
    useSelector((state: any) => state.app.body.connectStatus) || 0;
  const access = useSelector((state: any) => state.app.access.access) || null;
  let Theme; //(access && access.user && access.user.theme)
  if (access && access.user && access.user.theme) {
    if (access.user.theme === "default" || !access.user.theme)
      Theme = defaultTheme;
    if (access.user.theme === "dark") Theme = darkTheme;
    if (access.user.theme === "light") Theme = lightTheme;
    if (!Theme) Theme = defaultTheme;
  }

  const dispatch = useDispatch();

  let variant = 2;
  //Variant 1

  if (variant === 1 && config && isAuthenticated && !dispatchAccess && userId) {
    //URLAPI = getURLAPI(config)
    URL_WS = getURLAPI(config, "URL_WS"); //get address WebSocket BK from config
    setdispatchAccess(true);

    //    console.log('App dispatch FETCH_ACCESS!!!')
    /* dispatch({
      type: 'FETCH_ACCESS',
      urlapi: URLAPI,
      id: userId,
      token,
      URL_WS,
    })
*/
    try {
      wss_get_access({ id: userId, token, URL_WS }).then((res: any) => {
        //console.log('res', res)
        //let rez
        //setPbxId(res && res.user && res.user.pbxId)
        dispatch({
          type: "fetch/access",
          payload: res,
          ob: "access",
        });
      }); // Gets the response
    } catch (err) {
      console.log(err);
    }
  } else {
    if (dispatchAccess) {
      //      console.log('App dispatched FETCH_ACCESS!!! ')
    }
  }

  //Variant 2

  const setaccess = (access: any) => {
    //setPbxId(access && access.user && access.user.pbxId)
    dispatch({
      type: "fetch/access",
      payload: access,
      ob: "access",
    });
  };

  let wss: any; // SocketManager

  if (
    variant === 2 &&
    config &&
    isAuthenticated &&
    !dispatchAccess &&
    userId &&
    ws_client
  ) {
    //    URLAPI = getURLAPI(config)
    //    URL_WS = getURLAPI(config, 'URL_WS') //get address WebSocket BK from config

    wss = ws_client;
    // console.log('test wss.ready!!! ', wss.wss.ready)
    if (wss.wss.ready && wss.ws_login) {
      //wss.ws_login???
      //console.log('wss.ready && wss.ws_login!!! ', wss.wss.ready)
      try {
        wss.wss_getaccess(userId, setaccess);
        /*
        wss.wss_get_access(userId).then((res: any) => {
          //      wss_get_access({ id: userId, token, URL_WS }).then((res: any) => {
          //console.log('res', res)
          //let rez
          setPbxId(res && res.user && res.user.pbxId)
          dispatch({
            type: 'fetch/access',
            payload: res,
            ob: 'access',
          })
        }) // Gets the response
        */
      } catch (err) {
        console.log(err);
      }

      console.log("ascess true!!!");
      setdispatchAccess(true);
    } else {
      console.log("wss not ready!!! connectStatus", connectStatus);
      setTimeout(() => {
        dispatch({
          type: "fetch/get_add",
          payload: 1 + connectStatus,
          ob: "connectStatus",
        });
        /*
        dispatch({
          type: 'fetch/get_add',
          payload: wss.connectStatus,
          ob: 'connectStatus',
        })
        */
      }, 1000);
    }
  }

  if (false && config && isAuthenticated && !dispatchAccess && userId) {
    URLAPI = getURLAPI(config);
    setdispatchAccess(true);

    //    console.log('App dispatch FETCH_ACCESS!!!')
    dispatch({ type: "FETCH_ACCESS", urlapi: URLAPI, id: userId });
  } else {
    if (dispatchAccess && isAuthenticated) {
      //      console.log('App dispatched FETCH_ACCESS!!! ')
      if (
        access &&
        access.access &&
        access.access[0] &&
        access.access[0].logout
      ) {
        dispatch({ type: "FETCH_ACCESS", clearaccess: true });
        logout();
      }
    }
  }

  /*
  if (isAuthenticated && !dispatchMega && mg) {
    setdispatchMega(true)

    //const mega = JSON.parse(mg)
    console.log('App dispatch FETCH_MEGA!!!', mg)
    dispatch({ type: 'FETCH_MEGA', Mega: mg })
  } else {
    if (dispatchMega) {
      console.log('App dispatched FETCH_MEGA!!! ')
    }
  }
*/
  //  let title = 'Еженедельник'
  if (!ready || config === null || (access === null && isAuthenticated)) {
    return <Loader />;
  }

  //  setarg('test arg')
  return (
    <AuthContext.Provider
      value={{
        token,
        login,
        logout,
        userId,
        isAuthenticated,
        WorkDate,
        setdt,
        StartDate,
        setStartDate,
        EndDate,
        setEndDate,
        Worker,
        setWorker,
        Worker2,
        setWorker2,
        Customer,
        setCustomer,
        homeType,
        sethomeType,
        dispatchAccess,
        setdispatchAccess,
        config,
        setConfig,
        isRead,
        setIsRead,
        ws_client,
      }}
    >
      {(isAuthenticated && (
        <main className="main">
          <ReduxRouter history={history}>
            <I18nContextProvider value={i18nProvider}>
              <Toaster position="top-center" reverseOrder={false} />

              <MyLayout
                theme={Theme}
                //title={title}
              >
                <Routes>
                  <Route path="/" element={<Home />} />

                  <Route path="/pages/order/list" element={<OrderList />} />
                  <Route path="/pages/order/item" element={<OrderItem />} />

                  <Route path="/pages/head/list" element={<HeadList />} />
                  <Route path="/pages/head/item" element={<HeadItem />} />

                  <Route
                    path="/pages/supervisor/list"
                    element={<SupervisorList />}
                  />
                  <Route
                    path="/pages/supervisor/item"
                    element={<SupervisorItem />}
                  />

                  <Route path="/pages/manager/list" element={<ManagerList />} />
                  <Route path="/pages/manager/item" element={<ManagerItem />} />

                  <Route path="/pages/store/list" element={<StoreList />} />
                  <Route path="/pages/store/item" element={<StoreItem />} />

                  <Route path="/pages/route/list" element={<RouteList />} />
                  <Route path="/pages/route/item" element={<RouteItem />} />

                  <Route path="/pages/tovunit/list" element={<TovUnitList />} />
                  <Route path="/pages/tovunit/item" element={<TovUnitItem />} />

                  <Route path="/pages/tov/list" element={<TovList />} />
                  <Route path="/pages/tov/item" element={<TovItem />} />

                  <Route
                    path="/pages/typeprice/list"
                    element={<TypePriceList />}
                  />
                  <Route
                    path="/pages/typeprice/item"
                    element={<TypePriceItem />}
                  />

                  <Route path="/pages/price/list" element={<PriceList />} />
                  <Route path="/pages/price/item" element={<PriceItem />} />

                  <Route path="/pages/formpay/list" element={<FormPayList />} />
                  <Route path="/pages/formpay/item" element={<FormPayItem />} />

                  <Route path="/pages/vidpay/list" element={<VidPayList />} />
                  <Route path="/pages/vidpay/item" element={<VidPayItem />} />

                  <Route
                    path="/pages/customers/list"
                    element={<CustomersList />}
                  />
                  <Route
                    path="/pages/customers/item"
                    element={<CustomersItem />}
                  />

                  <Route
                    path="/pages/customers/set"
                    element={
                      <Navigate to="/pages/customers/list" replace={true} />
                    }
                  />

                  <Route
                    path="/pages/productvid/list"
                    element={<ProductVidList />}
                  />
                  <Route
                    path="/pages/productvid/item"
                    element={<ProductVidItem />}
                  />

                  <Route path="/auth/auth/list" element={<AuthList />} />
                  <Route path="/auth/auth/item" element={<AuthItem />} />

                  <Route path="/auth/auth/profile" element={<ProfilePage />} />
                  <Route path="/auth/auth/logout" element={<LogoutPage />} />

                  <Route path="/auth/config/item" element={<ConfigPage />} />

                  <Route
                    path="/auth/config/save"
                    element={<Navigate to="/" replace={true} />}
                  />

                  <Route
                    path="/auth/auth/save"
                    element={<Navigate to="/" replace={true} />}
                  />
                  <Route
                    path="/auth/auth/changepsw"
                    element={<Navigate to="/" replace={true} />}
                  />
                  <Route path="/auth/role/list" element={<RoleList />} />
                  <Route path="/auth/role/item" element={<RoleItem />} />

                  <Route
                    path="/auth/role/set"
                    element={<Navigate to="/auth/role/list" replace={true} />}
                  />
                  <Route path="/auth/unit/list" element={<UnitList />} />
                  <Route path="/auth/unit/item" element={<UnitItem />} />

                  <Route
                    path="/auth/unittype/list"
                    element={<UnitTypeList />}
                  />
                  <Route
                    path="/auth/unittype/item"
                    element={<UnitTypeItem />}
                  />

                  <Route
                    path="/auth/unit/set"
                    element={<Navigate to="/auth/unit/list" replace={true} />}
                  />
                  <Route
                    path="/auth/unittype/set"
                    element={
                      <Navigate to="/auth/unittype/list" replace={true} />
                    }
                  />
                  <Route
                    path="/auth/crontasks/list"
                    element={<CronTasksList />}
                  />
                  <Route
                    path="/auth/crontasks/item"
                    element={<CronTasksItem />}
                  />

                  <Route path="/pages/workers/list" element={<Workers />} />
                  <Route path="/pages/workers/item" element={<WorkersItem />} />

                  <Route path="/auth/session/list" element={<SessionList />} />

                  <Route path="/auth/amqp/list" element={<RabbitMQList />} />
                  <Route path="/auth/amqp/item" element={<RabbitMQItem />} />

                  <Route path="*" element={<NoMatch />} />
                </Routes>
              </MyLayout>
            </I18nContextProvider>
          </ReduxRouter>
        </main>
      )) ||
        (!isAuthenticated && (
          <main className="main">
            <ReduxRouter history={history}>
              <Container fluid>
                <Container style={{ marginTop: 5 }} fluid>
                  <Routes>
                    <Route path="/" element={<MyLogin />} />
                    <Route
                      path="/*"
                      element={<Navigate to="/" replace={true} />}
                    />
                  </Routes>
                </Container>
              </Container>
            </ReduxRouter>
          </main>
        ))}
    </AuthContext.Provider>
  );
};

export default App;
